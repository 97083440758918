import moment from 'moment';
import React, { FC, useState } from 'react';
import { Dialog, Button, TextField, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { agentLogin } from '../../modules/Auth/network/authCrud';
import { preFillZero } from '../utils/common-utils';
import { find } from 'lodash';
import { authenticate, setTokenInfo } from '../../modules/Auth/network/authSlice';
import { TokenInfo } from '../../modules/Auth/types/auth-types';
import { RedirectType } from '../types/common-types';
import { useIntl } from 'react-intl';

type LoginDialogProps = {
  open: boolean;
  redirect: RedirectType;
  onClose: () => void;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('xs')]: {
      padding: '25px 40px',
      width: '100vw',
    },
    [theme.breakpoints.up('sm')]: {
      padding: 25,
      minWidth: 350,
      width: 'auto',
      height: 'auto',
    },
  },
  header: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    marginBottom: 15,
    fontWeight: 500,
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  fieldContainer: {
    width: 100,
    boxSizing: 'border-box',
  },
  noPaddingFieldContainer: {
    flexBasis: '20%',
    minWidth: 120,
    boxSizing: 'border-box',
  },
  footer: {
    marginTop: 20,
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  agentLoginBtn: {
    padding: '10px 0',
    textTransform: 'none',
  },
  staffLoginBtn: {
    cursor: 'pointer',
    padding: 0,
    border: 0,
    backgroundColor: 'white',
  },
  divider: {
    borderBottom: '1px dashed #BAB2B1',
    margin: '20px 0',
  },
  noMargin: {
    margin: 0,
  },
}));

type ErrorState = {
  loginId: boolean;
  password: boolean;
};

const LoginDialog: FC<LoginDialogProps> = ({ open, redirect, onClose }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<ErrorState>({
    loginId: false,
    password: false,
  });

  const [loginId, setLoginId] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onSubmitErrorValidator = () => {
    let currentErrorState: ErrorState = {
      ...errorState,
      loginId: !!!loginId,
      password: !!!password,
    };
    setErrorState(currentErrorState);
    return currentErrorState;
  };

  const onAgentLogin = async () => {
    let currentState = onSubmitErrorValidator();
    if (find(currentState, (status) => status === true) === undefined) {
      setIsLoading(true);
      try {
        const res = await agentLogin(preFillZero(loginId), password, dispatch);
        dispatch(authenticate(res.accessToken));
        dispatch(setTokenInfo(jwt_decode(res.accessToken) as TokenInfo));
        const expireDate = moment(new Date()).add(res.expiresIn, 's').toDate();
        const abondonSession = moment(new Date()).add(1, 'd').toDate();
        window.localStorage.setItem('expireDate', expireDate.toISOString());
        window.localStorage.setItem('abondonSession', abondonSession.toISOString());
        window.localStorage.setItem('refreshToken', res.refreshToken);
        window.location.reload();
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDismissErrorHandler = (field: keyof ErrorState, value: any) => {
    if (value) {
      setErrorState({
        ...errorState,
        [field]: false,
      });
    }
  };

  return (
    <Dialog
      PaperProps={{
        classes: {
          root: classes.noMargin,
        },
      }}
      onClose={() => !isLoading && onClose()}
      open={open}
    >
      <div className={classes.container}>
        <div className={classes.header}>{Translation('common.login')}</div>
        <div style={{ marginBottom: 10 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>{Translation('common.loginId')} :</div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              placeholder={Translation('common.loginPlaceholder')}
              style={{ margin: 0 }}
              disabled={isLoading}
              error={errorState.loginId}
              margin="dense"
              variant="outlined"
              type="text"
              helperText={errorState.loginId && 'Required Field'}
              value={loginId}
              onChange={(e) => {
                const onlyAlphaNums = e.target.value.replace(/[^a-z0-9A-Z]/g, '');
                onDismissErrorHandler('loginId', onlyAlphaNums);
                setLoginId(onlyAlphaNums);
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 20 }} className={classes.rowContainer}>
          <div className={classes.fieldContainer}>{Translation('common.password')} :</div>
          <div style={{ flexGrow: 1 }}>
            <TextField
              fullWidth
              style={{ margin: 0, fontSize: 'initial' }}
              disabled={isLoading}
              error={errorState.password}
              margin="dense"
              variant="outlined"
              helperText={errorState.password && 'Required Field'}
              type="password"
              value={password}
              onChange={(e) => {
                onDismissErrorHandler('password', e.target.value);
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <Button
          className={classes.agentLoginBtn}
          fullWidth
          disabled={true} /* disable login for PACS */
          variant="contained"
          color="secondary"
          onClick={onAgentLogin}
        >
          {Translation('common.loginBtn')}{' '}
          {isLoading && <CircularProgress color="secondary" style={{ marginLeft: 8 }} size={15} />}
        </Button>
        {/* <div className={classes.divider}/>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {Translation("downloadPage.click")}
          <a
            style={{ marginLeft: 5, marginRight: 5 }}
            href={`${CORE_URL}/auth/aad?redirectUrl=${window.location.origin}/${redirect}/callback&subscription-key=${OEM_APIM_SUBSCRIPTION_KEY}&application=Agent%20Portal`}>
            {Translation("downloadPage.here")}
          </a>
          {Translation("downloadPage.staffLogin")}
        </div> */}
      </div>
    </Dialog>
  );
};

export default LoginDialog;
