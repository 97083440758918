import  { FC } from 'react';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { SurveyResultItem } from '../../../types/survey-types';
import 'survey-react/survey.min.css';
import { toAbsoluteUrl } from 'src/app/common/utils';
import '../components/index.css';
import { AgentInformation } from 'src/app/modules/Survey/pages/Preview/components/AgentInformation';

let defaultBanner = toAbsoluteUrl('/media/pruforce-logo/survey_default_banner.png');

type CustomerSurveyFormProps = {
  surveyId: string;
  surveyBody: Record<string, unknown>;
  surveyResult?: SurveyResultItem;
  surveyBanner?: string;
  agentProfile?: {
    displayName: string;
    designationName: string;
  };
};
const defaultProps: CustomerSurveyFormProps = {
  surveyBanner: undefined,
  surveyId: '',
  surveyBody: {},
};

StylesManager.applyTheme('defaultV2');

const CustomerSurveyForm: FC<CustomerSurveyFormProps> = ({
  surveyId,
  surveyBody,
  surveyResult,
  surveyBanner,
  agentProfile,
}) => {
  // const classes = useStyles();

  const model = new Model(surveyBody);

  model.css = {
    ...model.css,
    saveData: {
      ...model.css.saveData,
      error: 'saveErrorContainer',
      saveAgainButton: 'saveAgainBtn',
    },
    navigation: {
      prev: 'sd-navigation__prev-btn',
    },
  };

  if (surveyResult) {
    model.data = surveyResult.body;
    model.mode = 'display';
  } else {
    model.mode = 'display';
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
        }}
      >
        {agentProfile && (
          <AgentInformation agentName={agentProfile.displayName} agentTitle={agentProfile.designationName} />
        )}
        <div style={{ boxShadow: '0px 4px 10px #00000019' }}>
          {!surveyResult && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={surveyBanner ? surveyBanner : defaultBanner}
                style={{ objectFit: 'cover', width: '100%', height: undefined }}
                alt ="survey-banner"
              />
            </div>
          )}

          <div style={{ padding: '8px 16px 0', backgroundColor: 'white' }}>
            <div style={{ minHeight: 'max-content', overflowX: 'hidden' }}>
              <Survey model={model} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
CustomerSurveyForm.defaultProps = defaultProps;
export default CustomerSurveyForm;
