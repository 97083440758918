import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgentTypeItem, ChartListParam } from '../../../types/agent-types';
import { map } from 'lodash';
import { RootState } from 'src/redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import { DashboardItem, FCDashboardName, AlDashboardType, FCDashboardFilter } from '../../../types/dashboard-types';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useDataProvider } from 'src/app/common/utils';
import { fetchChartList, fetchFinancialAgentTypeList, fetchAlAgentList } from '../../../network/agentCrud';
import { fetchDownlineDivision, fetchDownlineAgentsList } from '../../../network/agentCrud'
import { ApiErrorResponse } from 'src/app/common/network';
import DownlineFcforGlList from './DownlineFcforGlList';
import { RouteComponentProps } from 'react-router';
import { useIntl } from 'react-intl';
import jwt_decode from 'jwt-decode';


type FinancialAgentListingPageProps = {} & ParamsProps & RouteComponentProps;

const initialState: ChartListParam = {
  periodRange: 'All',
  chartType: 'Table',
  filter: '',
  value: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): ChartListParam => {
  return initialParams
    ? {
        periodRange: 'All',
        chartType: 'Table',
        filter: '',
        value: '',
      }
    : initialState;
};

const DownlineFcforGlListingPage: FC<FinancialAgentListingPageProps> = ({
  initialParams,
  onChangeQueryParams,
  history,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const [filterState, setFilterState] = useState<ChartListParam>(paramsInitiator(initialParams));
  const [, setDashboardList] = useState<DashboardItem[]>();
  const [agentTypeList, setAgentTypeList] = useState<AgentTypeItem[]>();
  const [ allFcAgents, setAllFcAgents] = useState<any>([]);
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const user = useSelector<RootState, TokenInfo | undefined>(state => state.auth.user);
  const [myFilter, setMyFilter] = useState({
    monthly_dates__c: filterState.value,
    type: filterState.value,
  });
  const { isLoading, refreshData } = useDataProvider<DashboardItem[]>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        let res = await fetchChartList(filterState, dispatch);
        return res
      } catch (err) {}
    },
    setDashboardList,
    false,
  );
  useDataProvider<AgentTypeItem[]>(
    async () => {
      try {
        return (await fetchFinancialAgentTypeList(dispatch)).sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
      } catch (err) {}
    },
    setAgentTypeList,
    false,
  );


  useEffect(() => {
     refreshData()
  }, [filterState, sortKey]);

  const agent_code__c = user?.sub || '';

let arrayList: any = []
  useEffect(() => {
    fetchDownline(agent_code__c).then((results) => {
       let allAgentsList: any = results
       let allFcAgents: any = []
          allAgentsList && allAgentsList.map((item: any) => {
            allFcAgents = [...allFcAgents, item.groupLeader]
            fetchDownlineAgentsList(item.groupLeader, item.groupCode, dispatch)
            .then((response: any) => {
              const fcAgentList = response.map((item: any) => item.agentCode)
                     allFcAgents = [...allFcAgents, ...fcAgentList]
                     arrayList.push(allFcAgents)
                     setAllFcAgents(allFcAgents)
            })
            .catch((err: ApiErrorResponse) => {
              console.log(err)
            });
        })
      })
  },[agent_code__c])


const fetchDownline = (agent_code__c: string): Promise<any> => {
  if(agent_code__c){
    return fetchDownlineDivision(agent_code__c, dispatch)
  }
  return new Promise<any>((resolve,reject) => {
  })
}

const token = window.localStorage.getItem('jwt') || '';
let  role:any ;
if (token) {
 const tokenInfo = jwt_decode(token) as TokenInfo;
 role = tokenInfo.role;
}

  return (
    <>
      <PruFilter
        title={Translation('dashboard.common.controlPanel')}
        itemDef={[
          {
            type: PruFilterItemType.DROPDOWN_PERIOD,
            field: 'periodRange',
            initialValue: filterState.periodRange,
            defaultValue: initialState.periodRange,
            displayName: Translation('kpiDashboard.dashboard.period'),
            choices: [...map(FCDashboardName, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'chartType',
            initialValue: filterState.chartType,
            defaultValue: initialState.chartType,
            displayName: Translation('dashboard.common.type'),
            choices: [...map(AlDashboardType, (option: string) => ({ displayName: option, value: option }))],
          },
          {
            type: PruFilterItemType.DROPDOWN,
            field: 'filter',
            initialValue: filterState.filter,
            displayName: Translation('dashboard.common.filter'),
            choices: [
              { displayName: "", value: "" },
              ...map(FCDashboardFilter.get(filterState.chartType), (option: string) => ({ displayName: option, value: option }))
            ],
          },
          {
            type: PruFilterItemType.FREE_TEXT,
            field: 'value',
            initialValue: filterState.value,
            displayName: Translation('dashboard.common.value'),
          },
        ]}
        onChangeFilter={(data) =>
          setFilterState({
            ...filterState,
            periodRange: data.periodRange,
            chartType: data.chartType,
            filter: data.filter,
            value: data.value,
          })
        }
        fetchData={refreshData}
      />
     {(allFcAgents.length > 1) && (
      <DownlineFcforGlList
        filter={filterState}
        myFilter={myFilter}
        onRefresh={true}
        refreshdata={refreshData}
        setMyFilter={setMyFilter}
        agentList={allFcAgents}
        agentRole={role}
      />)}
    </>
  );
};

export default DownlineFcforGlListingPage;
