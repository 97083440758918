import React, { FC, useEffect ,PropsWithChildren} from 'react';
import { getToken } from '../../modules/Auth/network/authCrud';
import { authenticate, setTokenInfo } from '../../modules/Auth/network/authSlice';
import { TokenInfo } from '../../modules/Auth/types/auth-types';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { useUrlQueryReader } from '../utils';

const SingleSignOn: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const authorizationCode = params.get('authorizationCode');
  let query = useUrlQueryReader('authorizationCode');
  useEffect(() => {
    const login = async (code: string) => {
      if (code) {
        try {
          const response = await getToken(code, dispatch);
          const { accessToken, expiresIn, refreshToken } = response;
          if (accessToken && expiresIn && refreshToken) {
            window.localStorage.setItem('jwt', accessToken);
            dispatch(authenticate(accessToken));
            dispatch(setTokenInfo(jwt_decode(accessToken) as TokenInfo));
            const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
            const abondonSession = moment(new Date()).add(1, 'd').toDate();
            window.localStorage.setItem('expireDate', expireDate.toISOString());
            window.localStorage.setItem('abondonSession', abondonSession.toISOString());
            window.localStorage.setItem('refreshToken', refreshToken);
            let path = history.location.pathname;
            path += query;
            const noHeader = params.get('noHeader');
            if (noHeader !== null) {
              sessionStorage.setItem('noHeader', 'true');
            }
            history.push(path);
          }
        } catch (err) {
          history.push('/');
        }
      }
    };

    if (authorizationCode) {
      login(authorizationCode);
    }
    // eslint-disable-next-line
  }, []);

  return <>{authorizationCode ? <LayoutSplashScreen /> : children}</>;
};

export default SingleSignOn;
