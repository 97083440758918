import { BDMdashboardName, TopDashboardDetail } from './dashboard-types';

export const BDMdashboardDataProd = '6461459c-6cd4-40c8-812c-d817f4d794d4';

export enum LostReportTop {
  type = 'Both',
  dashboardLeft = '62e80a26-41d9-4e11-8e7f-5b0ac5e73277',
  dashboardRight = '6267bd98-d717-4757-8bff-7d34608e2384',
}

export enum SubmissionReportTop {
  type = 'Both',
  dashboardLeft = '1d9a81b7-6c42-4a5f-b2f2-ff42604f3c24',
  dashboardRight = '8f2af670-2ef7-454d-b3f6-4e927ef5e5b8',
}

export enum SubsourceTop {
  type = 'Both',
  dashboardLeft = '6273854d-2c86-442a-8275-5b28174f1bbd',
}

export enum AssignedTodayTop {
  type = 'Both',
  dashboardLeft = 'db45b716-bffb-4f64-8323-ed00315c9bb8',
}

export enum WeekCreateTop {
  type = 'Both',
  dashboardLeft = '6274f2de-c2b3-41a1-83a1-70e4924fc069',
}

export enum AverageTop {
  type = 'Both',
  dashboardLeft = '6274ea1f-f164-469e-8d95-81f43ee918de',
}

export enum StatusTop {
  type = 'Among',
  dashboardLeft = '62739ce3-94a3-44e5-84e0-071509249dba',
  dashboardRight = '62739a5d-c2b3-4483-84b0-70e492764a26',
}

export enum PerformanceTop {
  type = 'Among',
  dashboardLeft = '6273900f-2c86-4e2a-8779-5b2817527640',
  dashboardRight = '3d51dd35-dfe9-421e-91f6-b27e73ab343a',
}

export enum LostReasonTop {
  type = 'Unique',
  dashboardLeft = '62733279-8d58-42c8-893c-0a94cc74661f',
}

export const TopDashboardsProd: Map<string, any> = new Map<BDMdashboardName, TopDashboardDetail>([
  [BDMdashboardName.LOSTREPORT, LostReportTop],
  [BDMdashboardName.SUBMIISSIONREPORT, SubmissionReportTop],
  [BDMdashboardName.SUBSOURCE, SubsourceTop],
  [BDMdashboardName.ASSIGNEDTODAY, AssignedTodayTop],
  [BDMdashboardName.WEEKCREATE, WeekCreateTop],
  [BDMdashboardName.AVERAGE, AverageTop],
  [BDMdashboardName.STATUS, StatusTop],
  [BDMdashboardName.SOURCE, PerformanceTop],
  [BDMdashboardName.LOSTREASON, LostReasonTop],
]);

export const DashboardLibraryProd = {
  report: [
    {
      chartName: 'List of Participants',
      chartID: '6460e833-2da3-4a41-832e-6df9ee85fcf1',
      chartType: 'Table',
    },
    {
      chartName: 'List of Campaigns',
      chartID: '6460e833-2da3-42ad-89e3-6df9ee85fced',
      chartType: 'Table',
    },
    {
      chartName: 'List of Leads',
      chartID: '6460e833-2da3-4dae-85b9-6df9ee85fcef',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '64614289-6cd4-4cec-893f-d817f4d60627',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Lost Report',
      chartID: '64614289-6cd4-4b4c-8f6c-d817f4d60625',
      chartType: 'Chart',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '646142f2-ed0f-4c18-8fbe-19f687f63e6e',
      chartType: 'Table',
    },
    {
      chartName: 'Appointment to Submission Report',
      chartID: '646142f2-ed0f-480c-8650-19f687f63e6c',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '64614415-6e2f-43e5-8220-41609f975625',
      chartType: 'Chart',
    },
    {
      chartName: 'Assigned to Call Report',
      chartID: '64614415-6e2f-4172-8f40-41609f975629',
      chartType: 'Table',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '6461447e-6e2f-4bf5-8827-41609f97a453',
      chartType: 'Chart',
    },
    {
      chartName: 'Top Consultants by Lead Capture',
      chartID: '6461447e-6e2f-4a99-8635-41609f97a457',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6460f858-c8d5-4c9a-8a4a-98fcf11186f1',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Pending Call',
      chartID: '6460f858-c8d5-4ed8-80c9-98fcf11186ed',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6460f86c-2da3-49c1-808d-6df9ee95e08f',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Engagement',
      chartID: '6460f86c-2da3-4e73-81bd-6df9ee95e08b',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '6460f87d-6e2f-4608-8b16-41609f617a99',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Aging Report - Submission',
      chartID: '6460f87d-6e2f-4be2-8133-41609f617a95',
      chartType: 'Chart',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6460f890-6cd4-4d91-8b2b-d817f49fa9f3',
      chartType: 'Table',
    },
    {
      chartName: 'Cumulative Leads Aging Report',
      chartID: '6460f890-6cd4-4e60-8118-d817f49fa9ef',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '64613d48-8373-452f-8c7d-365aef419860',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Report by Sub Source',
      chartID: '64613d48-8373-47e0-8b52-365aef41985c',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '64613dca-d4d9-4f44-85b3-2dd970455578',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Stage Report',
      chartID: '64613dca-d4d9-428c-84ea-2dd970455572',
      chartType: 'Chart',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '64613e59-ed26-4d5e-8453-d7a9766e33b1',
      chartType: 'Table',
    },
    {
      chartName: 'Dropped-Off Report',
      chartID: '64613e59-ed26-4d4a-80f3-d7a9766e33af',
      chartType: 'Chart',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '64613ef4-5837-4cf8-8c7c-055a4e2e0426',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Status Report by Agent',
      chartID: '64613ef4-5837-4d7c-80c4-055a4e2e0422',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: '64613fa9-c04b-47a8-8a01-0eeea733c478',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Create Per Week',
      chartID: '64613fa9-c04b-4960-886b-0eeea733c474',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '6461401c-d4d9-4d26-8ba5-2dd97046d46a',
      chartType: 'Chart',
    },
    {
      chartName: 'Unaccepted Leads by Consultants',
      chartID: '6461401c-d4d9-4ca9-8072-2dd97046d46f',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '64614289-6cd4-4cec-893f-d817f4d60627',
      chartType: 'Table',
    },
    {
      chartName: 'Lead Lost Reason Report',
      chartID: '64614289-6cd4-4b4c-8f6c-d817f4d60625',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-4244-847f-b52ae38cf443',
      chartType: 'Table',
    },
    {
      chartName: 'Leads Assigned Today by Source',
      chartID: '628c4645-3ceb-485d-8c41-b52ae38cf43f',
      chartType: 'Chart',
    },
    {
      chartName: 'Average Conversion Time Report',
      chartID: '62b26a7b-2b11-4430-894a-6d176c49008b',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: '6460f353-2da3-4bcc-8e73-6df9ee90ce70',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days',
      chartID: '6460f353-2da3-4118-8b88-6df9ee90ce6c',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '6460f422-c04b-43c5-8ff9-0eeea7fb5b80',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance Report by Source',
      chartID: '6460f422-c04b-47d7-8d40-0eeea7fb5b7c',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '6460f4ae-ed0f-4ba5-8811-19f687bc04d1',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion performance by Lead Assigned Date',
      chartID: '6460f4ae-ed0f-4a1e-88b8-19f687bc04d5',
      chartType: 'Table',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '6460f56a-fdc5-4b93-8a32-b4de58f075cc',
      chartType: 'Chart',
    },
    {
      chartName: 'Conversion Performance by Last Updated Date',
      chartID: '6460f56a-fdc5-4e42-821a-b4de58f075ce',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '6460f61a-5837-43bf-8f24-055a4ef88a75',
      chartType: 'Table',
    },
    {
      chartName: 'Average Conversion Days by Consultants',
      chartID: '6460f61a-5837-4e10-83b0-055a4ef88a73',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: '63060d3e-0989-4d49-8304-795bb4e5dae2',
      chartType: 'Chart',
    },
    {
      chartName: 'Leads Create Per Week - phase 2',
      chartID: '63060d3e-0989-42f9-8362-795bb4e5dae4',
      chartType: 'Table',
    },
  ],
};
