import React, { FC, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useDispatch } from 'react-redux';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import {
  fetchDownlineDivision,
  fetchDownlineAgentsList,
  fetchGlAgentsList,
  fetchAlAgentList,
  fetchFilterGlAgentList,
} from '../../../network/agentCrud';
import { ApiErrorResponse } from 'src/app/common/network';
import { AlertType, appendAlertItem } from '../../../../../../redux/common/commonSlice';
import jwt_decode from 'jwt-decode';
import { useIntl } from 'react-intl';
import { DataGrid, GridColDef, GridCellParams, GridSortCellParams } from '@mui/x-data-grid';
// import { GridColDef,  } from '@mui/x-data-grid';
// import  Grid from '@mui/material/Grid';
// import { GridColDef, , GridRowsProp } from '@material-ui/data-grid';
import moment from 'moment';
// import { ForkRight } from '@mui/icons-material';
// import { Height } from '@material-ui/icons';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    '& .MuiIconButton-label': {
      color: '#016BF8',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize: '14px',
    },
    '& .MuiDataGrid-row': {
      fontSize: '14px',
    },
    '& .makeStyles-rowContainer-20': {
      marginTop: '-20px',
      Height: '600px',
    },
  },
  container: {
    width: 224,
    borderRight: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
  },
  rowContainer: {
    // display: 'flex',
    paddingTop: '1.2rem',
    alignItems: 'center',
    marginTop: 20,
    flexDirection: 'row',
    backgroundColor: theme.palette.common.white,
    width: '100vw',
    fontSize: '14px',
    height: '900px',
  },
  rowContainerEmpty: {
    // display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.common.white,
    width: '100vw',
    height: '100vw',
    fontSize: '14px',
    marginBottom: '-20px',
  },
  tableRow: {
    border: '1px solid #fff',
    padding: 4,
  },
  importFail: { marginTop: 20 },
  importFormat: { border: '1px solid #ccc', paddingTop: 10, paddingBottom: 10, borderRadius: 8, marginBottom: 20 },
  red: { color: '#E8192C' },
  heading: {
    fontSize: '1.3rem',
    textAlign: 'center',
    fontWeight: 600,
    color: '#001E2B',
    paddingBottom: '10px',
  },
  legend: { fontSize: '1rem', textAlign: 'center', width: '100vw', marginBottom: '5px' },

  columnHeader: { fontSize: '14px' },

  activeSortIcon: {
    opacity: 1,
    color: 'blue',
  },
  inactiveSortIcon: {
    opacity: 0.4,
    color: 'green',
  },
}));

type ChartListProps = {
  filter: any;
  myFilter: any;
  onRefresh?: boolean;
  refreshdata: () => void;
  setMyFilter: (data: any) => void;
};

const columns: GridColDef[] = [
  {
    field: 'Reporting Qtr',
    valueGetter: (params: GridCellParams) => `${params.row.reporting_quarter || 'unknown'}`,
    sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
      new Date(v1).getTime() > new Date(v2).getTime() ? 1 : -1,
    headerName: 'Reporting Quarter',
    headerAlign: 'center',
    width: 180,
    editable: false,
    align: 'center',
  },
  {
    field: 'agent_code__c',
    headerName: 'Agent Code',
    type: 'string',
    width: 150,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'business_name',
    headerName: 'Business Name',
    type: 'string',
    width: 180,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'al_count',
    headerName: 'AL Count',
    type: 'number',
    width: 150,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'percentageRed',
    headerName: '% Red AL',
    type: 'number',
    width: 150,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'percentageAmber',
    headerName: '% Amber AL',
    type: 'number',
    width: 150,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'percentageGreen',
    headerName: '% Green AL',
    type: 'number',
    width: 150,
    editable: false,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'overallRiskScore',
    headerName: 'GL 360 Overall Risk Score',
    type: 'string',
    width: 250,
    headerAlign: 'center',
    align: 'center',
    editable: false,
  },
  {
    field: 'reporting_quarter',
    headerName: 'Reporting Qtr',
    width: 150,
    editable: false,
    hideable: true,
  },
];
const GlAgentList: FC<ChartListProps> = ({ filter, myFilter, onRefresh, refreshdata, setMyFilter }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [userData, setUserData] = useState<any>();
  const [alAgent, setAlAgent] = useState<any>('');
  const [nodataAvailable, setNoDataAvailable] = useState<any>('');
  const [businessName, setBusinessName] = useState<any>('');
  const [gldataList, setGlDataList] = useState<any>([]);
  const [alCount, setAlCount] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>();
  const [allAlAgentList, setAllAlAgentList] = useState<any>();
  const [agentsRiskColorList, setAgentsRiskColorList] = useState<any>();
  const [percentageRed, setPercentageRed] = useState<any>([]);
  const [percentageAmber, setPercentageAmber] = useState<any>([]);
  const [percentageGreen, setPercentageGreen] = useState<any>([]);
  const [overallScore, setOverAllScore] = useState<any>([]);
  const [sortModel, setSortModel] = useState<any>([
    {
      field: 'Reporting Qtr',
      sort: 'desc',
    },
  ]);
  const { classes } = useStyles();

  let agent_code: any;
  const token = window.localStorage.getItem('jwt') || '';
  if (token) {
    const tokenInfo = jwt_decode(token) as TokenInfo;
    const { sub } = tokenInfo;
    agent_code = sub;
  }

  const getGlAgentsList = async (agent_code: string): Promise<any> => {
    if (agent_code) {
      return await fetchGlAgentsList(agent_code, dispatch);
    }
  };

  useEffect(() => {
    getGlAgentsList(agent_code).then((result) => {
      if (result) {
        setUserData(result[0]);
      }
    });
  }, []);

  const fetchDownline = (agent_code: string): Promise<any> => {
    if (agent_code) {
      return fetchDownlineDivision(agent_code, dispatch);
    }
    return new Promise<any>((resolve, reject) => {});
  };

  useEffect(() => {
    fetchDownline(agent_code).then((result) => {
      if (result) {
        const { groupCode } = result[0];
        fetchDownlineAgentsList(agent_code, groupCode, dispatch)
          .then((result) => {
            setAlAgent(result);
          })
          .catch((err: ApiErrorResponse) => {
            console.log(err);
          });
      }
    });
  }, []);

  const convert = (filterDate: any) => {
    var mnths: any = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12',
      },
      date = filterDate && filterDate.split(' ');
    return [date[3], mnths[date[1]], date[2]].join('-');
  };

  const fetchAgentList = async () => {
    let alAgentslist = await fetchAlAgentList().then((response) => response);
    let agentCodeList = alAgentslist.map((item: any) => {
      if (item.groupLeader === agent_code) {
        const fullname = item.groupName;
        const userbusinessname = fullname.split('-');
        setBusinessName(userbusinessname[1]);
      }
      return item.groupLeader;
    });
    setAllAlAgentList(agentCodeList);
    setAlCount(agentCodeList.length);
    return agentCodeList;
  };

  useEffect(() => {
    fetchAgentList();
  }, []);

  const fetchAlAgentData = async (agentCodeList: any) => {
    let allAvailableAgentData: any = [];
    if (agentCodeList) {
      for (let i = 0; i < agentCodeList.length; i++) {
        let allAlAgentData = await getGlAgentsList(agentCodeList[i]).then((response) => response);
        const glAgentName = allAlAgentData.filter((item: any) => {
          return item.groupLeader == agent_code;
        });
        if (allAlAgentData.length !== 0) {
          const agentOverAllRisk = allAlAgentData && allAlAgentData.map((item: any) => item.overal_risk__c);
          allAvailableAgentData.push(agentOverAllRisk);
        }
      }
      setAgentsRiskColorList(allAvailableAgentData);
      return allAvailableAgentData;
    }
  };

  useEffect(() => {
    fetchAlAgentData(allAlAgentList);
  }, [allAlAgentList]);

  useEffect(() => {
    if (agentsRiskColorList && agentsRiskColorList?.length !== 0) {
      let agentsOverAllRisk = [];
      for (let i = 0; i < agentsRiskColorList[0].length; i++) {
        let internalArray = [];
        for (let j = 0; j < agentsRiskColorList.length; j++) {
          internalArray.push(agentsRiskColorList[j][i]);
        }
        agentsOverAllRisk.push(internalArray);
      }
      const percentageRed: any = [];
      const percentageAmber: any = [];
      const percentageGreen: any = [];
      const overallRisk: any = [];

      for (let i = 0; i < agentsOverAllRisk.length; i++) {
        const redOccurrence = agentsOverAllRisk[i].filter((item: any) => item === 'RED').length;
        const amberOccurrence = agentsOverAllRisk[i].filter((item: any) => item === 'AMBER').length;
        const greenOccurrence = agentsOverAllRisk[i].filter((item: any) => item === 'GREEN').length;
        const totalOccurance = redOccurrence + amberOccurrence + greenOccurrence;
        const percentageRedAl: any = ((redOccurrence / totalOccurance) * 100).toFixed(2);
        const percentageAmberAl: any = ((amberOccurrence / totalOccurance) * 100).toFixed(2);
        const percentageGreenAl: any = ((greenOccurrence / totalOccurance) * 100).toFixed(2);
        percentageRed.push(percentageRedAl);
        percentageAmber.push(percentageAmberAl);
        percentageGreen.push(percentageGreenAl);
        const calculategl360OverallRiskScore = (
          percentageGreenAl: any,
          percentageAmberAl: any,
          percentageRedAl: any,
        ) => {
          if (percentageGreenAl > 80.0 || percentageGreenAl == 80.0) {
            return 'GREEN';
          } else if (percentageRedAl > 25.0 && percentageRedAl < 50.0) {
            return 'RED';
          } else {
            return 'AMBER';
          }
        };
        const gl360OverAllRiskScore = calculategl360OverallRiskScore(
          percentageGreenAl,
          percentageAmberAl,
          percentageRedAl,
        );
        overallRisk.push(gl360OverAllRiskScore);
      }
      setPercentageRed(percentageRed);
      setPercentageAmber(percentageAmber);
      setPercentageGreen(percentageGreen);
      setOverAllScore(overallRisk);
    }
  }, [agentsRiskColorList]);

  const addOverAllRiskColors = (responseObject: any) => {
    for (let i = 0; i < responseObject.length; i++) {
      responseObject[i]['percentageAmber'] = percentageAmber[i];
      responseObject[i]['percentageRed'] = percentageRed[i];
      responseObject[i]['percentageGreen'] = percentageGreen[i];
      responseObject[i]['overallRiskScore'] = overallScore[i];
    }
    return responseObject;
  };

  const glFilterValues = (agent_code: any, startDate: any, endDate: any) => {
    setNoDataAvailable('');
    fetchFilterGlAgentList(agent_code, startDate, endDate).then((result: any) => {
      let responseObject = result;
      const allReportingQtr = responseObject.map((item: any) => item.reporting_quarter__c);
      const formatedReporterQtr = allReportingQtr.map((item: any) => {
        const date = new Date(item);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const newReportingQtr = date && months[date.getMonth()] + ' ' + date.getFullYear();
        return newReportingQtr;
      });
      responseObject.map((item: any, index: any) => (item['reporting_quarter'] = formatedReporterQtr[index]));
      responseObject.map((item: any) => (item['al_count'] = alCount));
      const newResponseObject = addOverAllRiskColors(responseObject);
      newResponseObject.map((item: any) => (item['business_name'] = businessName));
      newResponseObject.forEach((item: any, i: number) => {
        item.id = i + 1;
      });
      if (percentageAmber.length !== 0) {
        console.log('no data available');
        setFilteredData(newResponseObject);
      }
    });
  };

  useEffect(() => {
    let startDate: any = filter.value && convert(filter.value[0].toString());
    let endDate: any = filter.value && convert(filter.value[1].toString());
    glFilterValues(agent_code, startDate, endDate);
  }, [filter.value, agent_code, alCount, gldataList, percentageRed]);

  useEffect(() => {
    if (filter.periodRange == 'All') {
      let startDate: any = '';
      let endDate: any = '';
      glFilterValues(agent_code, startDate, endDate);
    } else if (filter.periodRange == 'Last Quarter') {
      let startDate: any = new Date(moment(new Date()).subtract(1, 'quarter').startOf('quarter').format('MM/DD/YYYY'));
      let endDate: any = new Date(moment(new Date()).subtract(1, 'quarter').endOf('quarter').format('MM/DD/YYYY'));
      glFilterValues(agent_code, startDate, endDate);
    } else if (filter.periodRange == 'This Year') {
      let startDate: any = new Date(moment().startOf('year').format('MM/DD/YYYY'));
      let endDate: any = new Date(moment(new Date()).format('MM/DD/yyyy'));
      glFilterValues(agent_code, startDate, endDate);
    } else if (filter.periodRange == 'Last Year') {
      let startDate: any = new Date(moment(new Date()).subtract(1, 'year').startOf('year').format('MM/DD/YYYY'));
      let endDate: any = new Date(moment(new Date()).subtract(1, 'year').endOf('year').format('MM/DD/YYYY'));
      glFilterValues(agent_code, startDate, endDate);
    }
  }, [filter.periodRange, agent_code, alCount, gldataList, percentageRed]);

  console.log(filteredData?.length);
  useEffect(() => {
    if (filteredData?.length == 0) {
      setNoDataAvailable('No available result. Please refine your search');
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: Translation('kpiDashboard.agent.data.notfound.title'),
            content: Translation('kpiDashboard.agent.data.notfound.description'),
          },
        ]),
      );
    }
  }, [filteredData]);

  return (
    <>
      <div>{nodataAvailable}</div>
      <div className={classes.rowContainer}>
        <div className={classes.heading}>GL Performance</div>
        {filteredData?.length > 0 && (
          <>
            <div className={classes.legend}>
              [Green - Min 80% of ALs in group have green AL 360 overall risk scores] [Red - &lt;50% ALs in group have
              green AL 360 overall risk scores AND &gt; 25% ALs in group have red AL 360 overall risk scores] [Amber -
              Any other combinations]
            </div>
            <div style={{ fontSize: '14px', height: '800px', width: '100%' }}>
              <DataGrid
                className={classes.root}
                rows={filteredData}
                columns={columns}
                disableColumnMenu
                sortModel={sortModel}
                hideFooterPagination={true}
                onSortModelChange={(model) => setSortModel(model)}
                components={{
                  NoRowsOverlay: () => <div></div>,
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default GlAgentList;
