import React, { FC, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { authenticate, setTokenInfo } from '../network/authSlice';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { TokenInfo } from '../types/auth-types';
import { getToken } from '../network/authCrud';
import { LayoutSplashScreen } from 'src/app/layout';
import { appendAlertItem, AlertType } from '../../../../redux/common/commonSlice';
import { ParamsProps } from '../../../common/components/ParamsProvider';
import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../common/constants';
import { redirectMap, RedirectType } from '../../../common/types/common-types';

type CallbackProps = {} & ParamsProps;

const Callback: FC<CallbackProps> = ({ initialParams }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authorizationCode = initialParams ? initialParams.authorizationCode : '';
  const { redirect } = useParams<{ redirect: string }>();

  const redirectTo = useMemo(() => {
    return redirect && redirectMap[redirect as RedirectType] ? redirectMap[redirect as RedirectType] : '/';
  }, [redirect]);

  useEffect(() => {
    const login = async () => {
      if (authorizationCode) {
        try {
          const response = await getToken(authorizationCode, dispatch);
          const { accessToken, expiresIn, refreshToken } = response;
          if (accessToken && expiresIn && refreshToken) {
            window.localStorage.setItem('jwt', accessToken);
            dispatch(authenticate(accessToken));
            dispatch(setTokenInfo(jwt_decode(accessToken) as TokenInfo));
            const expireDate = moment(new Date()).add(Number(expiresIn), 's').toDate();
            const abondonSession = moment(new Date()).add(1, 'd').toDate();
            window.localStorage.setItem('expireDate', expireDate.toISOString());
            window.localStorage.setItem('abondonSession', abondonSession.toISOString());
            window.localStorage.setItem('refreshToken', refreshToken);
            if (redirectTo) {
              history.push(redirectTo);
            }
            return;
          }
        } catch (err) {}
      }
      history.push(redirectTo);
    };

    switch (redirect) {
      case RedirectType.APPLICATION_DOWNLOAD:
        if (!authorizationCode) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: 'Error',
                content: 'Authentication failure',
              },
            ]),
          );
          history.push('/');
          return;
        }
        login();
        return;
      default:
        history.push(NOT_FOUND_ERROR_PAGE_PATH);
        return;
    }
    // eslint-disable-next-line
  }, []);

  return <>{redirect === RedirectType.APPLICATION_DOWNLOAD && <LayoutSplashScreen />}</>;
};

export default Callback;
