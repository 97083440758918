import React, { useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Alert,
  Dialog,
  DialogTitle,
  Checkbox,
  Snackbar,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Icon,
} from '@mui/material';
import {
  AgentItem,
  AgentListParam,
  AgentPaginateList,
  CampaignDetailPageStep,
  CampaignStructureItem,
  campaignStructureSection,
} from '../../../../types/campaign-types';
import PruTable from '../../../../../../common/components/PruTable/PruTable';
import AddParticipantMenu from './components/AddParticipantMenu';
import { CloseOutlined } from '@mui/icons-material';
import ImportParticipantPopup from './components/ImportParticipantPopup';
import { useDispatch } from 'react-redux';
import { getBlobAsAgent } from '../../../../../../common/network/crud/resourceCrud';
import TnCItem from './components/TnCItem';
import { MANDATORY_FIELD_ERROR_TEXT } from '../../../../../../common/constants';
import { useDataProvider } from '../../../../../../common/utils/hook-utils';
import { addParticipant, fetchParticipantList, removeParticipant } from '../../../../network/campaignCurd';
import { useStyles } from './Participant.style';
import { get, set } from 'lodash';
import moment from 'moment';
import { removeFirstLetterIfU } from 'src/app/modules/AgencyCampaign/utils/common-utils';

interface ParticipantProps {
  campaignTypeStructureData: CampaignStructureItem;
  participantRange: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  setParticipantSelected: React.Dispatch<React.SetStateAction<any[]>>;
  disabledEdit?: boolean;
  disabled?: boolean;
  errorState: any;
  campaignObjId?: string;
  participantSelected: any[];
  validationHandler: any;
  startDate?: any;

  disableBulkSelect: boolean;
  setDisableBulkSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setRemoveSuccessDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sendInvitationToParticipant: (agentList?: string[]) => void;
  campaignOwner: string;
  isRequiredRsvp: boolean;
}

const paramsInitiator = (initialParams?: Record<string, string>): AgentListParam => {
  return {
    page: 1,
    limit: 20,
    search: '',
    id: '',
  };
};

const Participant: React.FC<ParticipantProps> = ({
  sectionKey,
  campaignTypeStructureData,
  participantRange,
  formDispatch,
  formState,
  setParticipantSelected,
  disabledEdit,
  disabled,
  errorState,
  campaignObjId,
  participantSelected,
  validationHandler,
  startDate,

  disableBulkSelect,
  setDisableBulkSelect,
  setRemoveSuccessDialogOpen,
  sendInvitationToParticipant,
  campaignOwner,
  isRequiredRsvp,
}) => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const dispatch = useDispatch();

  const [openSelectFromList, setOpenSelectFromList] = useState<boolean>(false);
  const [openImportFromFile, setOpenImportFromFile] = useState<boolean>(false);
  const [participantList, setParticipantList] = useState<AgentPaginateList>();

  const [filterState, setFilterState] = useState<AgentListParam>(paramsInitiator());
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [snackBar, setSnackBar] = useState<{ msg: string; visible: boolean }>({ msg: '', visible: false });

  const [removeOption, setRemoveOption] = useState<{
    open: boolean;
    applicantId: string | null;
  }>({ open: false, applicantId: null });

  const structureData = campaignTypeStructureData.sections.filter((item) => item.key == sectionKey)[0];
  const tNcField =
    structureData.sectionFields.filter((item) => item.key == 'tnc').length > 0
      ? structureData.sectionFields.filter((item) => item.key == 'tnc')[0]
      : null;
  const rsvpField =
    structureData.sectionFields.filter((item) => item.key == 'rsvpResponse').length > 0
      ? structureData.sectionFields.filter((item) => item.key == 'rsvpResponse')[0]
      : null;

  const arrparticipantName = structureData.sectionFields.filter((item) => item.key == 'participantName');
  const participantNameField = arrparticipantName.length > 0 ? arrparticipantName[0] : null;

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const getUniqueListBy = (arr: any[], key: string) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };
  const onRefresh = () => {};

  const setDoneImport = (data: AgentItem[]) => {
    var agentCodeArray = data.map((data) => {
      return { ...data, campaign: campaignObjId };
    });
    addParticipant(agentCodeArray, dispatch)
      .then((res) => {
        if (
          res.length > 0 &&
          campaignTypeStructureData.sections
            .filter((item) => item.key === 'participant')[0]
            .sectionFields.filter((item) => item.key === 'rsvpResponse').length > 0
        ) {
          //TODO: send invitation
          sendInvitationToParticipant(agentCodeArray.map((item) => item.agentCode));
        }

        setSnackBar({
          msg: res.length + Translation('agencyCampaign.create.importDone'),
          visible: true,
        });
        refreshData();
      })
      .catch((e) => {});
  };

  const { isLoading, refreshData } = useDataProvider<AgentPaginateList>(
    async () => {
      try {
        if (campaignObjId) {
          const data = await fetchParticipantList({ ...filterState, id: campaignObjId }, sortKey, dispatch);
          return data;
        }
      } catch (err) {}
    },
    setParticipantList,
    false,
  );

  // TODO: handle single remove
  const toggleRemoveDialog = () => {
    setRemoveOption((prev) => ({
      open: !prev.open,
      applicantId: null,
    }));
  };

  const doRemove = () => {
    let applicantId = removeOption.applicantId;
    if (applicantId && campaignObjId) {
      const data = {
        id: campaignObjId,
        agentList: [applicantId],
      };
      removeParticipant(data, dispatch).then(() => {
        setParticipantSelected((prev) => prev.filter((item: any) => item.agentCode !== removeOption.applicantId));
        setRemoveSuccessDialogOpen(true);
      });
    }
    setRemoveOption({
      open: false,
      applicantId: null,
    });
  };

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [campaignObjId, participantSelected, filterState, sortKey]);

  const closeSnackBar = () => setSnackBar({ msg: '', visible: false });

  const renderNumberLimit = () => {
    const { gte: min, lte: max } = participantRange;
    if (!max && !min) return null;
    return (
      <ul className={classes.numberLimitList}>
        {min > 0 && <li>{`${min} ${Translation('agencyCampaign.create.participantMin')}`}</li>}
        {max > 0 && <li>{`${max} ${Translation('agencyCampaign.create.participantMax')}`}</li>}
      </ul>
    );
  };
  return (
    <>
      <div className={classes.addBtnWrap}>
        <div style={{ fontWeight: 600 }}>{Translation('agencyCampaign.create.editParticipant')}</div>
        <div>
          {!disabledEdit && !disabled && (
            <AddParticipantMenu
              campaignObjId={campaignObjId}
              onSelectFromList={() => setOpenSelectFromList(true)}
              onImportFromFile={() => setOpenImportFromFile(true)}
            />
          )}
        </div>
      </div>
      <div style={{}}>{renderNumberLimit()}</div>
      {!disabledEdit && !disabled && (
        <Button
          variant="outlined"
          color="inherit"
          className={classes.btnBulkSelect}
          onClick={() => {
            setDisableBulkSelect((prev) => !prev);
            if (!disableBulkSelect) {
              setParticipantSelected([]);
            }
          }}
        >
          {disableBulkSelect
            ? Translation('agencyCampaign.create.select')
            : Translation('agencyCampaign.create.unselect')}
        </Button>
      )}
      <div style={{ flex: 1, backgroundColor: 'white', padding: 20 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBar.visible}
          autoHideDuration={5000}
          onClose={closeSnackBar}
          message={snackBar.msg}
        />
        <PruTable
          hideListTitleRow={true}
          title={''}
          disableBulkSelect={disableBulkSelect}
          operationDef={[]}
          columnDef={[
            {
              keyIndex: `unit`,
              align: 'center',
              displayName: Translation(`agencyCampaign.common.unitCode`),
              renderData: (row) => (row.unit ? (removeFirstLetterIfU(row.unit) as string) : '-'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'unit', value: sort['unit'] });
              },
            },
            {
              keyIndex: 'businessName',
              align: 'center',
              displayName: Translation('agencyCampaign.common.businessName'),
              renderData: (row) => ((row as AgentItem).name?.enUs?.displayName as string) ?? '-',
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'name', value: sort['businessName'] });
              },
            },
            {
              isId: true,
              keyIndex: 'agentCode',
              align: 'center',
              displayName: Translation('agencyCampaign.common.agentCode'),
              renderData: (row) => (row.agentCode ? (row.agentCode as string) : '-'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'agentCode', value: sort['agentCode'] });
              },
            },
            {
              keyIndex: 'rsvp',
              align: 'center',
              displayName: Translation('agencyCampaign.common.rsvp'),
              sortable: true,
              onSort: (sort) => {
                updateSortingKey({ key: 'rsvpIndicator', value: sort['rsvp'] });
              },
              renderData: ({ invitationTime, rsvpIndicator }) =>
                invitationTime !== undefined && rsvpIndicator !== undefined && rsvpIndicator !== 'Pending'
                  ? rsvpIndicator
                  : moment().isAfter(startDate)
                  ? 'No Response'
                  : invitationTime !== undefined && rsvpIndicator !== undefined
                  ? rsvpIndicator
                  : '-',
              hidden: !isRequiredRsvp,
            },
            {
              hidden: disabledEdit || disabled,
              keyIndex: 'action',
              align: 'center',
              displayName: Translation('agencyCampaign.common.action'),
              renderData: (row) => {
                if (row.agentCode === campaignOwner) return '';
                return (
                  <span
                    onClick={() => {
                      //TODO: do single delete
                      setRemoveOption({
                        open: true,
                        applicantId: row.agentCode,
                      });
                    }}
                    className="tw-text-red-600 tw-cursor-pointer"
                  >
                    {Translation('app.button.remove').toUpperCase()}
                  </span>
                );
              },
            },
          ]}
          isLoading={false}
          onRefresh={onRefresh}
          dataSource={participantList?.docs ? participantList.docs : []}
          totalPages={participantList?.totalPages ?? 1}
          totalRecords={participantList?.totalDocs ?? 0}
          onChangePage={(page, rowsPerPage) => {
            setFilterState({
              ...filterState,
              page: page,
              limit: rowsPerPage,
            });
          }}
          currentSelectedRow={(data) => {
            setParticipantSelected((prev) => {
              // un-select all
              if (data.length === 0 && prev.length > 0) return [];
              // select all
              if (data.length === 0 && prev.length === 0)
                return participantList?.docs
                  ? participantList.docs.filter((item) => item.agentCode !== campaignOwner)
                  : [];
              // select or un-select one
              return data;
            });
          }}
          // hideBulkSelectHeader={true}
          bulkSelectCheckboxDisable={(data) => {
            return data.agentCode === campaignOwner;
          }}
          updateSelectedRow={participantSelected}
          type="participants"
        />
      </div>
      {/** Select from List Dialog */}
      <Dialog
        open={openSelectFromList}
        disableEscapeKeyDown
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setOpenSelectFromList(false);
          }
        }}
        maxWidth="md"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.create.button.selectFromList')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              setOpenSelectFromList(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <ImportParticipantPopup
          selectFromFile={false}
          campaignObjId={campaignObjId || ''}
          campaignTypeStructureData={campaignTypeStructureData}
          doneImport={(data) => {
            setDoneImport(data);
            setOpenSelectFromList(false);
          }}
          onCancel={() => {
            setOpenSelectFromList(false);
          }}
        />
      </Dialog>
      {/** Import from File Dialog */}
      <Dialog
        disableEscapeKeyDown
        open={openImportFromFile}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setOpenImportFromFile(false);
          }
        }}
        maxWidth="md"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.create.button.importFromFile')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              setOpenImportFromFile(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        <ImportParticipantPopup
          selectFromFile={true}
          campaignObjId={campaignObjId || ''}
          campaignTypeStructureData={campaignTypeStructureData}
          doneImport={(data) => {
            setDoneImport(data);
            setOpenImportFromFile(false);
          }}
          onCancel={() => {
            setOpenImportFromFile(false);
          }}
        />
      </Dialog>
      {tNcField ? (
        <>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              classes={{
                root: classes.checkboxItem,
                checked: classes.checked,
              }}
              disabled={disabledEdit || disabled}
              onClick={(e: any) => {
                validationHandler.onDismissErrorHandler(`${sectionKey}_tnc`, e.target.checked);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionKey, field: 'tnc', value: e.target.checked },
                });
              }}
              checked={formState[sectionKey] && formState[sectionKey]['tnc'] ? formState[sectionKey]['tnc'] : false}
            />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {`Agree to `}
              {tNcField.value &&
                (tNcField.value as any[]).map((item, index) => {
                  return <TnCItem tNcItem={item} index={index} key={index} />;
                })}
            </div>
          </div>
          {formState.stepPopulated.includes(CampaignDetailPageStep.PARTICIPANT) &&
            errorState.mandatory[`${sectionKey}_tnc`] && (
              <span className={classes.errorText}>{MANDATORY_FIELD_ERROR_TEXT}</span>
            )}
        </>
      ) : null}

      {/* remove participant dialog start */}
      <Dialog
        open={removeOption.open}
        onClose={toggleRemoveDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.removeTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translation('agencyCampaign.create.removeText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnRemoveWrap}>
          <Button onClick={toggleRemoveDialog} variant="outlined" color="inherit">
            {Translation('app.button.cancel')}
          </Button>
          <Button onClick={doRemove} variant="contained" color="secondary" autoFocus>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* remove participant dialog end */}
    </>
  );
};

export default Participant;
