import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon } from '@mui/material';
import React, { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { AlertType, appendAlertItem } from '../../../../../../redux/common/commonSlice';
import { ApiErrorResponse } from '../../../../../common/network/apiErrorHandler';
import {
  cancelCampaignSubmission,
  checkAllRsvpResponded,
  createNewCampaign,
  fetchCampaignItem,
  fetchCampaignTypeStructure,
  fetchCurrentAgentInfo,
  fetchParticipantList,
  removeParticipant,
  sendInvitation,
  submitCampaign,
  updateCampaign,
  uploadLead,
} from '../../../network/campaignCurd';
import { CampaignDetailPageStep, CampaignStructureItem } from '../../../types/campaign-types';
import Attachment from './application/Attachment';
import CampaignInfo from './application/CampaignInfo';
import Participant from './application/Participant';
import Approval from './approval/Approval';
import CampaignDetailPageLeftBar from './DetailPageLeftBar/CampaignDetailPageLeftBar';
import Lead from './lead/Lead';
// import { NOT_FOUND_ERROR_PAGE_PATH } from '../../../../../common/constants';
import { get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { useLang } from '../../../../../i18n';
import { createBlobAsAgent, getBlobAsAgent } from '../../../../../common/network/crud/resourceCrud';
import { fileUpload } from '../../../../../common/resource/resource-utils';
import {
  convertArrayToObject,
  FormMode,
  getDisplayName,
  pathExtractor,
} from '../../../../../common/utils/common-utils';
import { ErrorFieldType, useErrorHandler } from '../../../../../common/utils/form-error-utils';
import {
  AgencyCampaignTypeStatusEnum,
  AGENCY_CAMPAIGN_BASE_PATH,
  AGENCY_CAMPAIGN_CAMPAIGN_PATH,
} from '../../../constants';
import {
  validationCompareChecking,
  validationLengthChecking,
  validationMandatoryDependsChecking,
  validationCurrencyChecking,
} from '../../../utils/validations';
import { InvitePreview } from './InviteDialog/InvitePreview';
// import classes from './CampaignDetailPage.module.css';
import { useStyles } from './CampaignDetailPage.style';
import { PruToast } from 'src/app/common/components/pru-toast';
import LeadV2 from './lead/lead-v2';
import EventDocument from './post-event-documents/post-document';
import { addBusinessDays } from 'date-fns';
import { checkAgentImportFail, renderfailImportList } from './application/components/ImportParticipantPopup';
import { ModalService } from 'src/app/common/components/pru-modal/modal-service';
import { useGAScreenViewEvent } from '../../../../../common/ga/hook/useGAScreenViewEvent';
import NoAccess from './no-access/no-access';

type ModifyFieldAction = {
  type: 'MODIFY_FIELD';
  payload: {
    section: string;
    field: keyof any;
    value: any;
  };
};

type StepAccessAction = {
  type: 'STEP_ACCESS';
  payload: number;
};

type StepPopulateAction = {
  type: 'STEP_POPULATE';
  payload: number | number[];
};

type InitFieldAction = {
  type: 'INIT_FORM_DATA';
  payload: {
    value: any;
  };
};

type infoDataFormAction = ModifyFieldAction | InitFieldAction | StepAccessAction | StepPopulateAction;

const initialState: any = {
  stepAccessed: [],
  stepPopulated: [],
  count: 0,
};

const infoDataFormReducer = (state: any, action: infoDataFormAction): any => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.section]: {
          ...state[action.payload.section],
          [action.payload.field]: action.payload.value,
        },
        count: state.count + 1,
      };
    case 'INIT_FORM_DATA':
      let formState = {
        ...state,
        ...action.payload.value,
        count: state.count + 1,
      };
      return formState;
    case 'STEP_ACCESS':
      return {
        ...state,
        stepAccessed: [...new Set(state.stepAccessed.concat(action.payload))],
      };
    case 'STEP_POPULATE':
      const stepPopulated = [...new Set(state.stepPopulated.concat(action.payload))];
      return {
        ...state,
        stepPopulated,
      };
  }
};

const StateContext = React.createContext({});
const DispatchContext = React.createContext({});

const CampaignDetailPage: FC<RouteComponentProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string, variable?: Record<string, string>) => intl.formatMessage({ id }, variable);
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });

  const { typeId } = useParams<{ typeId: string }>();
  const { id } = useParams<{ id: string }>();
  const { code } = useMemo(() => ({ code: id ? id : undefined }), [id]);
  const { formMode } = pathExtractor(location.pathname, `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}`);

  const [currentCampaign, setCurrentCampaign] = useState<any>({ campaignTypeId: typeId ? typeId : '' });
  const [campaignTypeStructureData, setCampaignTypeStructureData] = useState<CampaignStructureItem>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [step1Warning, setStep1Warning] = useState<boolean>(true);
  const [step2Warning, setStep2Warning] = useState<boolean>(true);
  const [step3Warning, setStep3Warning] = useState<boolean>(true);
  const [formState, formDispatch] = useReducer(infoDataFormReducer, initialState);
  const [previousFormState, setPreviousFormState] = useState<any>({});
  const [participantSelected, setParticipantSelected] = useState<any[]>([]);
  const [validationArray, setValidationArray] = useState<any[]>([]);
  const [currentAgentInfo, setCurrentAgentInfo] = useState<any>();
  const [leadUploadList, setLeadUploadList] = useState<any>();
  const [leadUploadFile, setLeadUploadFile] = useState<any>();
  const [submitDateShouldGreaterThan, setSubmitDateShouldGreaterThan] = useState<any>();
  const [campaignEndDateEqualToStartDate, setCampaignEndDateEqualToStartDate] = useState<any>();
  const [campaignEndDateGreaterThanTarget, setCampaignEndDateGreaterThanTarget] = useState<any>();
  const [RSVPMandatory, setRSVPMandatory] = useState<boolean>(false);
  const [formStateUpdated, setFormStateUpdated] = useState<boolean>(false);
  const [showDiscradPrompt, setShowDiscardPrompt] = useState<boolean>(false);
  const [participantNameRules, setParticipantNameRules] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [disableBulkSelect, setDisableBulkSelect] = useState<boolean>(true);
  const [removeSuccessDialogOpen, setRemoveSuccessDialogOpen] = useState<boolean>(false);
  const [inviteSuccessDialogOpen, setInviteSuccessDialogOpen] = useState<boolean>(false);
  const [leadUploadError, setLeadUploadError] = useState<string | null>();

  const lang = useLang();
  /** remove participant confirm dialog start */
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);
  const handleOk = () => {
    setOpen(false);
    removeSelectedParticipant();
  };
  /** remove participant confirm dialog end */

  const [approvalData, setApprovalData] = useState<any>([]);

  /** invite dialog start */
  // Invitation sent or not
  const [sentInvite, setSentInvite] = useState<boolean>(false);
  const [inviteDialog, setInviteDialog] = useState<boolean>(false);
  const toggleInviteDialog = () => {
    setInviteDialog(!inviteDialog);
    setSentInvite(false);
  };
  const onClickInvite = async () => {
    const max = get(participantNameRules, 'lte', 0);
    if (max) {
      setLoading(true);
      const getParticipantList = await fetchParticipantList(
        {
          limit: 10000,
          page: 1,
          search: '',
          id: currentCampaign.campaignObjId,
        },
        [],
        dispatch,
      );
      setLoading(false);
      let invitedCount = 0;
      getParticipantList?.docs.map((item) => {
        if (item.invitationTime) {
          invitedCount++;
        }
      });
      const totalCount = participantSelected.length + invitedCount;
      if (totalCount > max) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('agencyCampaign.create.submitFail'),
              content: `${max} ${Translation('agencyCampaign.create.participantMax')}`,
            },
          ]),
        );
        return;
      }
    }
    toggleInviteDialog();
  };
  /** invite dialog end */

  const getCurrentAgentInfo = () => {
    fetchCurrentAgentInfo(lang, dispatch)
      .then((result) => {
        setCurrentAgentInfo(result);
      })
      .catch((err: ApiErrorResponse) => {
        // history.push(NOT_FOUND_ERROR_PAGE_PATH);
      });
  };

  const disabledEdit = useMemo(() => {
    const disableForEventDocument =
      ((currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.PENDING ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.APPROVED ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.ACTIVE ||
        currentCampaign.campaignStatus == AgencyCampaignTypeStatusEnum.COMPLETED) &&
        currentStep !== CampaignDetailPageStep.EVNET_DOCUMENT) ||
      currentCampaign?.freyaMigratedInd;
    return disableForEventDocument;
  }, [currentCampaign.campaignStatus, currentStep, currentCampaign?.freyaMigratedInd]);

  const [disableUserActions, setDisableUserActions] = useState<boolean>(false);

  const disabled = useMemo(() => {
    // disabled edit for PCAAEB-28275
    if (!currentAgentInfo) {
      getCurrentAgentInfo();
    } else {
      if (currentAgentInfo?.['agentCode'] && formState?.applicant?.applicantAgentCode) {
        return currentAgentInfo['agentCode'] !== formState.applicant.applicantAgentCode;
      } else {
        return false;
      }
    }
  }, [formState, currentAgentInfo]);

  const reloadData = async () => {
    setDisableUserActions(true);
    if (currentCampaign.campaignId) {
      await fetchCampaignItem(currentCampaign.campaignId, dispatch)
        .then((result) => {
          formDispatch({ type: 'INIT_FORM_DATA', payload: { value: result.agencyCampaign } });
          const _currentCampaign = {
            ...currentCampaign,
            campaignTypeId: result.agencyCampaign.typeId,
            campaignStatus: result.status,
            approvalHistoryList: result.agencyCampaign.approvalHistoryList,
            remarksList: result.agencyCampaign.remark,
            campaignObjId: result._id,
            previousApprovalData: result.agencyCampaign.previousApprovalHistories,
            campaignTypeStructureData: result.agencyCampaign._campaignType,
            freyaMigratedInd: result?.freyaMigratedInd ? result.freyaMigratedInd : null,
            campaignOwner: result.createdBy,
            rsvpStatus: result.agencyCampaign?.rsvpStatus ? result.agencyCampaign?.rsvpStatus : null,
            isRequiredRsvp: !!result.agencyCampaign?.isRequiredRsvp,
            applicantAgentCode: result?.agencyCampaign?.applicant?.applicantAgentCode,
          };
          if (result.agencyCampaign.submitDate) {
            const { submitDate, cancelDate, canceledBy, cancelReason } = result.agencyCampaign;
            _currentCampaign.submitData = {
              submitDate: submitDate,
              submitBy: result.agencyCampaign.applicant.applicantName,
              cancelDate: cancelDate,
              canceledBy: canceledBy,
              cancelReason,
            };
          }
          _currentCampaign.startDate = moment(result.startDate).toDate();
          _currentCampaign.endDate = moment(result.endDate).toDate();
          setCurrentCampaign(_currentCampaign);
          if (_currentCampaign?.freyaMigratedInd) {
            setDisableUserActions(_currentCampaign.freyaMigratedInd);
          } else {
            setDisableUserActions(false);
          }
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    setCurrentCampaign({ ...currentCampaign, campaignId: code });
  }, [code]);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line
  }, [currentCampaign.campaignId]);

  useEffect(() => {
    loadCampaignTypeStructure();
    if (formMode == FormMode.CREATE) {
      getCurrentAgentInfo();
    }
    // eslint-disable-next-line
  }, [currentCampaign.campaignTypeId]);

  useEffect(() => {
    autoPopulatedField();
  }, [currentAgentInfo, campaignTypeStructureData]);

  const autoPopulatedField = () => {
    if (!campaignTypeStructureData || !currentAgentInfo) {
      return;
    }
    campaignTypeStructureData.sections
      .filter(
        (item: any) => item.key != 'participant',
        //  && item.key != "attachments"
      )
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldsItem: any) => {
          if (!(formState[sectionItem.key] && formState[sectionItem.key][fieldsItem.key])) {
            switch (fieldsItem.key) {
              case 'applicantName':
                const displayName = getDisplayName(currentAgentInfo, lang);
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: displayName },
                });
                break;
              case 'applicantAgentCode':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    section: sectionItem.key,
                    field: `${fieldsItem.key}`,
                    value: currentAgentInfo['agentCode'],
                  },
                });
                break;
              case 'applicantPhoneNumber':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: {
                    section: sectionItem.key,
                    field: `${fieldsItem.key}`,
                    value: currentAgentInfo['phone']['mobile'],
                  },
                });
                break;
              case 'applicantEmail':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: currentAgentInfo['email'] },
                });
                break;
              case 'numsOfPolicy':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: '0' },
                });
                break;
              case 'totalApe':
                formDispatch({
                  type: 'MODIFY_FIELD',
                  payload: { section: sectionItem.key, field: `${fieldsItem.key}`, value: '$0' },
                });
                break;
              default:
                break;
            }
          }
        });
      });
  };

  const loadCampaignTypeStructure = () => {
    if (currentCampaign.campaignTypeStructureData) {
      const result = currentCampaign.campaignTypeStructureData;
      setCampaignTypeStructureData(result);
      handleValidationArray(result);
      setApprovalData(result.approvalSetting);
      return;
    }
    if (currentCampaign.campaignTypeId) {
      fetchCampaignTypeStructure(currentCampaign.campaignTypeId, dispatch)
        .then((result) => {
          setCampaignTypeStructureData(result);
          handleValidationArray(result);
          setApprovalData(result.approvalSetting);
        })
        .catch((err: ApiErrorResponse) => {
          // history.push(NOT_FOUND_ERROR_PAGE_PATH);
        });
    }
  };

  const validationHandler = useErrorHandler(formState, validationArray);
  useEffect(() => {
    if (validationArray?.length && currentCampaign.campaignId) {
      checkStepHaveError();
    }
  }, [validationArray]);

  const handleValidationArray = (dataStructure: any) => {
    const mandatoryArray: any[] = [];
    dataStructure.sections
      .filter((item: any) => !['participant'].includes(item.key))
      .map((sectionItem: any) => {
        sectionItem.sectionFields.map((fieldItem: any) => {
          let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
          if (ruleObject.Mandatory && ruleObject.Mandatory.key == 'mandatory' && ruleObject.Mandatory.value) {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
            });
          }
          if (ruleObject.Mandatory && ruleObject.Mandatory.key == 'mandatoryDepends') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.MANDATORY,
              condition: (updatedFormState: any) => {
                return validationMandatoryDependsChecking(
                  sectionItem.key,
                  fieldItem.key,
                  updatedFormState,
                  ruleObject.Mandatory,
                );
              },
            });
          }

          if (ruleObject.Validation) {
            switch (ruleObject.Validation.key) {
              case 'length':
                mandatoryArray.push({
                  section: sectionItem.key,
                  name: fieldItem.key,
                  fieldType: ErrorFieldType.IMMEDIATE,
                  condition: (updatedFormState: any) => {
                    return validationLengthChecking(
                      sectionItem.key,
                      fieldItem.key,
                      updatedFormState,
                      ruleObject.Validation,
                    );
                  },
                });
                break;
              case 'compare':
                if (ruleObject.Validation.dependsOn && ruleObject.Validation.dependsOn.indexOf('submitDate') >= 0) {
                  setSubmitDateShouldGreaterThan({
                    section: sectionItem.key,
                    field: fieldItem.key,
                    value: ruleObject.Validation.value,
                  });
                } else if (
                  fieldItem.key == 'campaignEndDate' &&
                  ruleObject.Validation.dependsOn &&
                  ruleObject.Validation.dependsOn.indexOf('campaignStartDate') >= 0
                ) {
                  if (ruleObject.Validation.operator == 'eq' && ruleObject.Validation.value == 0) {
                    setCampaignEndDateEqualToStartDate({
                      setEqual: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                    });
                  }
                  if (ruleObject.Validation.operator == 'gte' && ruleObject.Validation.value > 0) {
                    setCampaignEndDateGreaterThanTarget({
                      setGreateThan: true,
                      sectionKey: sectionItem.key,
                      startDateItem: 'campaignStartDate',
                      fieldKey: fieldItem.key,
                      targetDays: Number(ruleObject.Validation.value),
                    });
                  }
                } else {
                  mandatoryArray.push({
                    section: sectionItem.key,
                    name: fieldItem.key,
                    fieldType: ErrorFieldType.IMMEDIATE,
                    condition: (updatedFormState: any) => {
                      return validationCompareChecking(
                        sectionItem.key,
                        fieldItem.key,
                        updatedFormState,
                        ruleObject.Validation,
                      );
                    },
                  });
                  break;
                }
                break;
              default:
                break;
            }
          }

          if (fieldItem.type == 'currency') {
            mandatoryArray.push({
              section: sectionItem.key,
              name: fieldItem.key,
              fieldType: ErrorFieldType.IMMEDIATE,
              condition: (updatedFormState: any) => {
                return validationCurrencyChecking(sectionItem.key, fieldItem.key, updatedFormState);
              },
            });
          }
        });
      });

    const participantSection =
      dataStructure.sections.filter((item: any) => item.key == 'participant').length > 0
        ? dataStructure.sections.filter((item: any) => item.key == 'participant')[0]
        : undefined;

    if (participantSection) {
      const participantSectionField = convertArrayToObject(participantSection.sectionFields, 'key');
      if (participantSectionField['tnc']) {
        let ruleObject = convertArrayToObject(participantSectionField['tnc'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          mandatoryArray.push({
            section: 'participant',
            name: 'tnc',
            fieldType: ErrorFieldType.MANDATORY,
          });
        }
      }
      if (participantSectionField['rsvpResponse']) {
        let ruleObject = convertArrayToObject(participantSectionField['rsvpResponse'].rules, 'title');
        if (ruleObject.Mandatory && ruleObject.Mandatory.value == true) {
          setRSVPMandatory(true);
        }
      }
      if (participantSectionField['participantName']) {
        let compareRules = participantSectionField['participantName'].rules.filter(
          (item: any) => item.key == 'compare',
        );
        let compareRulesObject = convertArrayToObject(compareRules, 'operator');
        let setRules = {
          ...(compareRulesObject.gte && compareRulesObject.gte.value && { gte: compareRulesObject.gte.value }),
          ...(compareRulesObject.lte && compareRulesObject.lte.value && { lte: compareRulesObject.lte.value }),
        };
        setParticipantNameRules(setRules);
      }
    }
    setValidationArray(mandatoryArray);
  };

  useEffect(() => {
    validationHandler.immediateErrorValidator();

    // Set campaignEndDate be same as campaignStartDate if validation rule applied
    if (campaignEndDateEqualToStartDate && campaignEndDateEqualToStartDate.setEqual) {
      const sectionKey = campaignEndDateEqualToStartDate.sectionKey;
      const fieldKey = campaignEndDateEqualToStartDate.fieldKey;
      const startDayKey = campaignEndDateEqualToStartDate.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndOfStartDay =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).endOf('day').toDate()
          : null;
      if (dayEndOfStartDay && !moment(currentEndDay).isSame(dayEndOfStartDay)) {
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndOfStartDay },
        });
      }
    }

    if (campaignEndDateGreaterThanTarget?.setGreateThan) {
      const sectionKey = campaignEndDateGreaterThanTarget.sectionKey;
      const fieldKey = campaignEndDateGreaterThanTarget.fieldKey;
      const startDayKey = campaignEndDateGreaterThanTarget.startDateItem;
      const currentEndDay =
        formState[sectionKey] && formState[sectionKey][fieldKey]
          ? formState[sectionKey] && formState[sectionKey][fieldKey]
          : null;
      const dayEndDate =
        formState[sectionKey] && formState[sectionKey][startDayKey]
          ? moment(formState[sectionKey][startDayKey]).add(campaignEndDateGreaterThanTarget.targetDays, 'days').toDate()
          : null;
      if (dayEndDate && !moment(currentEndDay).isSame(dayEndDate)) {
        validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldKey}`, dayEndDate);
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { section: sectionKey, field: `${fieldKey}`, value: dayEndDate },
        });
      }
    }
    if (currentCampaign.campaignId) {
      if (currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) {
        if (
          (!isEmpty(previousFormState.campaign) &&
            !isEmpty(formState.campaign) &&
            !isEqual(previousFormState.campaign, formState.campaign)) ||
          (!isEmpty(previousFormState.applicant) &&
            !isEmpty(formState.applicant) &&
            !isEqual(previousFormState.applicant, formState.applicant))
        ) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
        if (
          !isEmpty(previousFormState.participant) &&
          !isEmpty(formState.participant) &&
          !isEqual(previousFormState.participant, formState.participant)
        ) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.ATTACHMENT) {
        if (!isEmpty(formState.attachments) && !isEqual(previousFormState.attachments, formState.attachments)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.EVNET_DOCUMENT) {
        if (
          !isEmpty(previousFormState.postDocuments) &&
          !isEmpty(formState.postDocuments) &&
          !isEqual(previousFormState.postDocuments, formState.postDocuments)
        ) {
          setFormStateUpdated(true);
        }
      }
    } else {
      if (currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) {
        if (!isEqual(previousFormState.campaign, formState.campaign)) {
          setFormStateUpdated(true);
        }
        if (!isEmpty(previousFormState.applicant) && isEqual(previousFormState.applicant, formState.applicant)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
        if (!isEqual(previousFormState.participant, formState.participant)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.ATTACHMENT) {
        if (!isEqual(previousFormState.attachments, formState.attachments)) {
          setFormStateUpdated(true);
        }
      }
      if (currentStep === CampaignDetailPageStep.EVNET_DOCUMENT) {
        if (!isEqual(previousFormState.postDocuments, formState.postDocuments)) {
          setFormStateUpdated(true);
        }
      }
    }
    setPreviousFormState(formState);

    // eslint-disable-next-line
  }, [formState.count]);

  /** if step one has error */
  const checkStepHaveError = (currentStep: number = 0): Record<string, boolean> => {
    let isInfoValid = true;
    let isParticipantValid = true;
    let isAttachmentValid = true;
    if (campaignTypeStructureData) {
      const infoSection: string[] = [];
      const participantSection = [];
      const attachmentSection = [];
      for (let index = 0; index < campaignTypeStructureData.sections.length; index++) {
        const section = campaignTypeStructureData.sections[index];
        if ('postDocuments' !== section.key) {
          if (section.key === 'participant') {
            participantSection.push(section.key);
          } else if (section.key === 'attachments') {
            attachmentSection.push(section.key);
          } else {
            infoSection.push(section.key);
          }
        }
      }
      const { currentErrorState, formState } = validationHandler.onSubmitErrorValidator();
      // need to check both mandatory and immedia error
      const mandatoryErrorState = { ...currentErrorState.mandatory };
      const immediateErrorState = { ...currentErrorState.immediate };
      for (const key in mandatoryErrorState) {
        if (Object.prototype.hasOwnProperty.call(mandatoryErrorState, key)) {
          const status = mandatoryErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
          if (participantSection.includes(sectionKey) && status) {
            isParticipantValid = false;
          }
          if (attachmentSection.includes(sectionKey) && status) {
            isAttachmentValid = false;
          }
        }
      }

      for (const key in immediateErrorState) {
        if (Object.prototype.hasOwnProperty.call(immediateErrorState, key)) {
          const status = immediateErrorState[key];
          const sectionKey = key.toString().split('_')[0];
          if (infoSection.includes(sectionKey) && status && isInfoValid) {
            isInfoValid = false;
          }
        }
      }
      setStep1Warning(!isInfoValid);
      setStep2Warning(!isParticipantValid);
      setStep3Warning(!isAttachmentValid);
    }
    return { isInfoValid, isParticipantValid, isAttachmentValid };
  };

  const onSubmitDocuments = async () => {
    const postDocs = formState.postDocuments;
    postDocs.triggerSubmit = true;
    const details = {
      postDocuments: formState.postDocuments,
      applicant: formState.applicant,
    };

    const { hasError } = validationHandler.onSubmitErrorValidator({
      include: {
        postDocuments: true,
      },
    });
    if (hasError && campaignTypeStructureData) {
      const submitFail = Translation('agencyCampaign.create.submitFail');
      checkStepHaveError();
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      return;
    }
    await updateCampaign(details, currentCampaign.campaignId, dispatch);
    await reloadData();
    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Event document saved successfully`,
        },
      ]),
    );
    setFormStateUpdated(false);
  };

  const onSaveCampaign = async ({ quite }: any): Promise<any> => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { campaignTypeName, ...formStateData } = formState;
    const details = {
      ...formStateData,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      ...(!formState.participant && { participant: { list: [] } }),
    };
    const fromSubmit = quite;
    if (!formState.campaign && !fromSubmit) {
      setFormStateUpdated(false);
      return;
    } else {
      formDispatch({
        type: 'STEP_POPULATE',
        payload: currentStep,
      });
    }
    // check step1 error only
    const { isInfoValid, isParticipantValid, isAttachmentValid } = checkStepHaveError(currentStep);

    if (!currentCampaign.campaignId) {
      if (!isInfoValid) {
        setFormStateUpdated(false);
        return;
      }
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        window.history.replaceState(null, '', `/agencyCampaign/campaign/detail/${saveResult.campaignId}`);
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId, campaignObjId: saveResult._id });
      }
      reloadData();
    } else {
      if (!isInfoValid && fromSubmit) {
        setCurrentStep(CampaignDetailPageStep.CAMPAIGN_INFO);
      }
      if (!isParticipantValid) {
        if (fromSubmit) {
          setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
        } else {
          if (currentStep === CampaignDetailPageStep.PARTICIPANT) {
            const saveFail = Translation('component.status.failed');
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: saveFail,
                  content: Translation('agencyCampaign.create.fillAll'),
                },
              ]),
            );
          }
        }
      }
      if (!isAttachmentValid && fromSubmit) {
        setCurrentStep(CampaignDetailPageStep.ATTACHMENT);
      }
      if (
        (!isInfoValid && currentStep === CampaignDetailPageStep.CAMPAIGN_INFO) ||
        (!isParticipantValid && currentStep === CampaignDetailPageStep.PARTICIPANT) ||
        (!isAttachmentValid && currentStep === CampaignDetailPageStep.ATTACHMENT)
      ) {
        return;
      }
      await updateCampaign(details, currentCampaign.campaignId, dispatch);
    }
    // reset form status after saving
    setFormStateUpdated(false);
    if (!quite) {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Campaign saved successfully`,
          },
        ]),
      );
    }
    if (!quite && currentStep < CampaignDetailPageStep.ATTACHMENT) {
      setCurrentStep(currentStep + 1);
    }
    return true;
  };

  const onCancelCampaign = async () => {
    const data = {
      id: currentCampaign.campaignObjId,
    };
    await cancelCampaignSubmission(data, dispatch).then(() => {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.SUCCESS,
            title: 'Success',
            content: `Cancel submission successfully`,
          },
        ]),
      );
      reloadData();
    });
  };
  const submitCampaignRequest = async (
    details: any,
    campaignId: string,
    campaignObjId: string,
    importList: any,
    validateParticipantRules: Function,
  ): Promise<any> => {
    const participantSectionTypeStructureData: any = campaignTypeStructureData?.sections.filter(
      (item) => item.key == 'participant',
    )[0];
    const participantNameRules =
      participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName').length > 0
        ? participantSectionTypeStructureData.sectionFields.filter((item: any) => item.key == 'participantName')[0]
            .rules
        : [];
    const eligibilityRules: string[] =
      participantNameRules.filter((item: any) => item.key == 'eligibility').length > 0
        ? participantNameRules.filter((item: any) => item.key == 'eligibility')[0].value
        : [];
    const needEligibilityCheck = (eligibilityRules as string[]).length > 0;
    if (needEligibilityCheck && importList) {
      const { successList, failList } = await checkAgentImportFail(
        importList,
        campaignTypeStructureData,
        campaignObjId,
        eligibilityRules,
        dispatch,
        lang,
      );
      if (failList?.length) {
        await ModalService.open({
          title: Translation('agencyCampaign.create.eligibilityCheck'),
          children: renderfailImportList(failList, async () => {
            const agentCodeList = failList.map(({ agentCode }: any) => {
              return agentCode;
            });
            const data = {
              id: currentCampaign.campaignObjId,
              agentList: agentCodeList,
            };
            await removeParticipant(data, dispatch).then(async () => {
              const passValidator = await validateParticipantRules(successList);
              if (!passValidator) {
                return;
              }
              return submitCampaign(details, campaignId, dispatch);
            });
          }),
          maxWidth: 'md',
          fullWidth: false,
          contentContainerStyle: { padding: '0 20px' },
        });
      } else {
        await submitCampaign(details, campaignId, dispatch);
      }
    } else {
      await submitCampaign(details, campaignId, dispatch);
    }
  };
  const onSubmitCampaign = async () => {
    formDispatch({
      type: 'STEP_POPULATE',
      payload: [
        CampaignDetailPageStep.CAMPAIGN_INFO,
        CampaignDetailPageStep.PARTICIPANT,
        CampaignDetailPageStep.ATTACHMENT,
      ],
    });
    const result = await onSaveCampaign({ quite: true });
    if (!result) {
      return;
    }
    setFormStateUpdated(false);
    const submitFail = Translation('agencyCampaign.create.submitFail');
    const startDate = get(formState, `${submitDateShouldGreaterThan.section}.${submitDateShouldGreaterThan.field}`);

    if (startDate) {
      const currentDate = new Date();
      let minStartDate = addBusinessDays(currentDate, submitDateShouldGreaterThan.value);
      if (moment(startDate).diff(minStartDate, 'days') < 0) {
        const msg =
          submitDateShouldGreaterThan.value === 0
            ? Translation('agencyCampaign.create.submit')
            : TranslationWithVariable('agencyCampaign.create.submit.days', submitDateShouldGreaterThan.value);
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: msg,
            },
          ]),
        );
        setCurrentStep(CampaignDetailPageStep.CAMPAIGN_INFO);
        return;
      }
    }

    let { hasError, currentErrorState } = validationHandler.onSubmitErrorValidator({
      exclude: {
        postDocuments: true,
      },
    });

    if (hasError && campaignTypeStructureData) {
      checkStepHaveError(currentStep);
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: submitFail,
            content: Translation('agencyCampaign.create.fillAll'),
          },
        ]),
      );
      return;
    }

    //Check amount of participant
    let getParticipantList: any;
    const validateParticipantRules = async (participants?: any[]) => {
      if (participantNameRules) {
        getParticipantList = participants
          ? { docs: participants, totalDocs: participants.length }
          : await fetchParticipantList(
              {
                limit: 10000,
                page: 1,
                search: '',
                id: currentCampaign.campaignObjId,
              },
              [],
              dispatch,
            );
        const { gte: min, lte: max } = participantNameRules;
        if (min) {
          if (getParticipantList.totalDocs < min) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${min} ${Translation('agencyCampaign.create.participantMin')}`,
                },
              ]),
            );
            setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
            return;
          }
        }
        if (max) {
          if (getParticipantList.totalDocs > max) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  title: submitFail,
                  content: `${max} ${Translation('agencyCampaign.create.participantMax')}`,
                },
              ]),
            );
            setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
            return;
          }
        }
      }
      return true;
    };
    const passValidator = await validateParticipantRules();
    if (!passValidator) {
      return;
    }

    // Check if RSVP respond is needed
    if (RSVPMandatory && currentCampaign.campaignObjId) {
      const isAllRsvpResponsed = await checkAllRsvpResponded(currentCampaign.campaignObjId, dispatch);
      if (!isAllRsvpResponsed.allResponded) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: submitFail,
              content: `Please collect all the RSVP response before submit`,
            },
          ]),
        );
        setCurrentStep(CampaignDetailPageStep.PARTICIPANT);
        return;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { campaignTypeName, ...formStateData } = formState;
    const details = {
      ...formStateData,
      typeId: campaignTypeStructureData?._id || '',
      typeCode: campaignTypeStructureData?.code || '',
      ...(!formState.participant && { participant: { list: [] } }),
    };
    if (!currentCampaign.campaignId) {
      const saveResult = await createNewCampaign(details, dispatch);
      if (saveResult.campaignId) {
        setCurrentCampaign({ ...currentCampaign, campaignId: saveResult.campaignId });
        await submitCampaignRequest(
          details,
          saveResult.campaignId,
          saveResult._id,
          getParticipantList?.docs,
          validateParticipantRules,
        );
        reloadData();
      }
    } else {
      await submitCampaignRequest(
        details,
        currentCampaign.campaignId,
        currentCampaign.campaignObjId,
        getParticipantList?.docs,
        validateParticipantRules,
      );
      reloadData();
    }

    dispatch(
      appendAlertItem([
        {
          severity: AlertType.SUCCESS,
          title: 'Success',
          content: `Submitted successfully`,
        },
      ]),
    );

    setCurrentStep(CampaignDetailPageStep.APPROVAL);
    // backToList();
  };

  const onImportLead = async () => {
    if (currentCampaign.campaignId) {
      const file = leadUploadFile;
      try {
        setLoading(true);
        const createBlobRes = await createBlobAsAgent(
          { mimeType: file.type, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
          dispatch,
        );
        await fileUpload(createBlobRes[0].url, file, () => {});
        const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
        const result = blobDetail[0];
        if (result) {
          const details = {
            campaignId: currentCampaign.campaignId,
            resourceId: result.blobId,
            filename: file.name,
          };

          await uploadLead(details, dispatch);
          // clear state when import done
          setLeadUploadList(undefined);
          setLeadUploadFile(undefined);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('agencyCampaign.lead.processing'),
                content: Translation('agencyCampaign.lead.uploadDone'),
              },
            ]),
          );
        }
      } catch (err) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: 'Fail',
              content: `Lead upload failed`,
            },
          ]),
        );
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(
        appendAlertItem([
          {
            severity: AlertType.ERROR,
            title: 'Fail',
            content: `Lead upload failed`,
          },
        ]),
      );
    }
  };

  const backToList = () => {
    if (displaySaveBtn && !disabledEdit && formStateUpdated && !disabled) {
      PruToast({
        message: Translation('agencyCampaign.common.saveToast'),
      });
      return;
    }
    history.push(`/agencyCampaign/campaign`);
  };

  const removeSelectedParticipant = () => {
    const agentCodeList = participantSelected.map(({ agentCode }: any) => {
      return agentCode;
    });

    if (currentCampaign.campaignObjId) {
      const data = {
        id: currentCampaign.campaignObjId,
        agentList: agentCodeList,
      };
      removeParticipant(data, dispatch).then(() => {
        setDisableBulkSelect(false);
        setParticipantSelected([]);
        setRemoveSuccessDialogOpen(true);
      });
    }
  };

  const sendInvitationToParticipant = (agentList?: string[]) => {
    const agentCodeList =
      agentList ||
      participantSelected.map(({ agentCode }: any) => {
        return agentCode;
      });
    if (currentCampaign.campaignObjId) {
      const data = {
        id: currentCampaign.campaignObjId,
        agentList: agentCodeList,
      };

      sendInvitation(data, dispatch)
        .then(() => {
          // setParticipantSelected([]);
          // setSentInvite(true);
          setInviteSuccessDialogOpen(true);
        })
        .catch((err) => {
          console.log('err===', err);
        });
    }
  };

  const invitePreviewData = {
    campaignType: campaignTypeStructureData?.agentLeadSource || '--',
    campaignName: formState?.campaign?.campaignName,
    startDate: formState?.campaign?.campaignStartDate,
    endDate: formState?.campaign?.campaignEndDate,
  };
  let submitDateTips = '';
  if (submitDateShouldGreaterThan?.value) {
    submitDateTips = TranslationWithVariable('agencyCampaign.create.submit.days', submitDateShouldGreaterThan.value);
  }

  const displaySaveBtn =
    currentStep === CampaignDetailPageStep.CAMPAIGN_INFO ||
    currentStep <= CampaignDetailPageStep.ATTACHMENT ||
    currentStep === CampaignDetailPageStep.EVNET_DOCUMENT;

  const onStepChange = (stepNumber: number) => {
    formDispatch({
      type: 'STEP_ACCESS',
      payload: stepNumber,
    });
    if (currentStep === stepNumber) {
      return;
    }
    if (displaySaveBtn && !disabledEdit && formStateUpdated && !disabled) {
      PruToast({
        message: Translation('agencyCampaign.common.saveToast'),
      });
      return;
    }
    setCurrentStep(stepNumber);
  };

  const updateRSVPStatusAction = (rsvpStatus: string) => {
    setCurrentCampaign((prev: any) => ({ ...prev, rsvpStatus }));
  };

  return (
    <>
      <DispatchContext.Provider value={formDispatch}>
        <StateContext.Provider value={formState}>
          <div className={classes.container}>
            <CampaignDetailPageLeftBar
              campaignTypeStructureData={campaignTypeStructureData}
              onCancel={onCancelCampaign}
              onSubmit={onSubmitCampaign}
              submitDateTips={submitDateTips}
              onBack={backToList}
              formState={formState}
              currentStep={currentStep}
              step1Warning={step1Warning}
              step2Warning={step2Warning}
              step3Warning={step3Warning}
              onStepChange={(stepNumber) => {
                onStepChange(stepNumber);
              }}
              disabledEdit={disabledEdit}
              disabled={disabled}
              startDate={currentCampaign.startDate}
              disableUserActions={disableUserActions}
              rsvpStatus={currentCampaign.rsvpStatus}
              campaignId={currentCampaign.campaignObjId}
              campaignOwner={currentCampaign.campaignOwner}
              campaignStatus={currentCampaign.campaignStatus ?? AgencyCampaignTypeStatusEnum.DRAFT}
              applicantAgentCode={currentCampaign.applicantAgentCode}
              updateRSVPStatusAction={updateRSVPStatusAction}
            />
            <div className={classes.rightContentContainer}>
              <div className={classes.rightContent}>
                {campaignTypeStructureData ? (
                  currentStep == CampaignDetailPageStep.CAMPAIGN_INFO ? (
                    <CampaignInfo
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      formState={formState}
                      errorState={validationHandler.errorState}
                      errorMessages={validationHandler.errorMessages}
                      campaignStatus={currentCampaign.campaignStatus}
                      disabledEdit={disabledEdit}
                      disabled={disabled}
                      campaignId={currentCampaign.campaignId}
                      validationHandler={validationHandler}
                      campaignEndDateEqualToStartDate={campaignEndDateEqualToStartDate}
                      campaignEndDateGreaterThanTarget={campaignEndDateGreaterThanTarget}
                    />
                  ) : currentStep == CampaignDetailPageStep.PARTICIPANT ? (
                    <NoAccess
                      hasAuth={currentCampaign?.applicantAgentCode === user.sub || !currentCampaign.campaignOwner}
                    >
                      <Participant
                        campaignTypeStructureData={campaignTypeStructureData}
                        participantRange={participantNameRules}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'participant'}
                        setParticipantSelected={setParticipantSelected}
                        errorState={validationHandler.errorState}
                        disabledEdit={disabledEdit}
                        disabled={disabled}
                        campaignObjId={currentCampaign.campaignObjId}
                        participantSelected={participantSelected}
                        validationHandler={validationHandler}
                        startDate={currentCampaign.startDate}
                        disableBulkSelect={disableBulkSelect}
                        setDisableBulkSelect={setDisableBulkSelect}
                        setRemoveSuccessDialogOpen={setRemoveSuccessDialogOpen}
                        sendInvitationToParticipant={sendInvitationToParticipant}
                        campaignOwner={currentCampaign.campaignOwner}
                        isRequiredRsvp={currentCampaign.isRequiredRsvp}
                      />
                    </NoAccess>
                  ) : currentStep == CampaignDetailPageStep.ATTACHMENT ? (
                    <NoAccess
                      hasAuth={currentCampaign?.applicantAgentCode === user.sub || !currentCampaign.campaignOwner}
                    >
                      <Attachment
                        campaignTypeStructureData={campaignTypeStructureData}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'attachments'}
                        errorState={validationHandler.errorState}
                        disabledEdit={disabledEdit}
                        disabled={disabled}
                        validationHandler={validationHandler}
                      />
                    </NoAccess>
                  ) : currentStep == CampaignDetailPageStep.APPROVAL ? (
                    <Approval
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      approvalHistoryList={currentCampaign.approvalHistoryList}
                      remarkList={currentCampaign.remarksList}
                      sectionKey={'approval'}
                      submitData={currentCampaign.submitData}
                      campaignObjId={currentCampaign.campaignObjId}
                      approvalData={approvalData}
                      previousApprovalData={currentCampaign.previousApprovalData}
                    />
                  ) : currentStep === CampaignDetailPageStep.EVNET_DOCUMENT ? (
                    <EventDocument
                      campaignTypeStructureData={campaignTypeStructureData}
                      formDispatch={formDispatch}
                      formState={formState}
                      sectionKey={'postDocuments'}
                      onSubmitDocuments={onSubmitDocuments}
                      errorState={validationHandler.errorState}
                      // disabledEdit={disabledEdit}
                      validationHandler={validationHandler}
                      campaign={currentCampaign}
                      disableUserActions={disableUserActions}
                      disabled={disabled}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {/* use hide or show to keep the Lead data */}
                {campaignTypeStructureData &&
                  (campaignTypeStructureData.addLeadSetting ? (
                    <div style={{ display: currentStep !== CampaignDetailPageStep.LEAD ? 'none' : '' }}>
                      <LeadV2
                        campaignTypeStructureData={campaignTypeStructureData}
                        campaign={currentCampaign}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'approval'}
                        onLeadUploadList={(data) => {
                          setLeadUploadList(data);
                        }}
                        onLeadFileUpload={(data) => {
                          setLeadUploadFile(data);
                        }}
                        disableUserActions={disableUserActions}
                        onLeadUploadError={(data) => {
                          setLeadUploadError(data);
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ display: currentStep !== CampaignDetailPageStep.LEAD ? 'none' : '' }}>
                      <Lead
                        campaignTypeStructureData={campaignTypeStructureData as any}
                        campaignStatus={currentCampaign.campaignStatus}
                        formDispatch={formDispatch}
                        formState={formState}
                        sectionKey={'approval'}
                        onLeadUploadList={(data) => {
                          setLeadUploadList(data);
                        }}
                        onLeadFileUpload={(data) => {
                          setLeadUploadFile(data);
                        }}
                        disableUserActions={disableUserActions}
                        campaignId={currentCampaign.campaignId}
                        onLeadUploadError={(data) => {
                          setLeadUploadError(data);
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div className={classes.bottomButtonContainer}>
                {participantSelected.length > 0 && currentStep === CampaignDetailPageStep.PARTICIPANT ? (
                  <>
                    {/* {campaignTypeStructureData &&
                    campaignTypeStructureData.sections
                      .filter((item) => item.key == 'participant')[0]
                      .sectionFields.filter((item) => item.key == 'rsvpResponse').length > 0 ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        disabled={loading}
                        className={classes.bottomRedButton}
                        onClick={onClickInvite}
                      >
                        {Translation('app.button.invite')}
                      </Button>
                    ) : null} */}

                    <Button
                      variant="outlined"
                      color="inherit"
                      size="large"
                      className={classes.bottomWhiteButton}
                      onClick={toggleOpen}
                    >
                      {Translation('app.button.remove')}
                    </Button>
                  </>
                ) : displaySaveBtn ? (
                  <>
                    {!disabled && !disabledEdit && formStateUpdated && (
                      <>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classes.bottomRedButton}
                          onClick={onSaveCampaign}
                        >
                          {Translation('app.button.save')}
                        </Button>

                        <Button
                          variant="outlined"
                          color="inherit"
                          className={classes.bottomWhiteButton}
                          onClick={() => {
                            setShowDiscardPrompt(true);
                          }}
                        >
                          {Translation('app.button.discard')}
                        </Button>
                      </>
                    )}
                  </>
                ) : currentStep == CampaignDetailPageStep.LEAD &&
                  leadUploadList &&
                  leadUploadFile &&
                  !leadUploadError ? (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      disabled={loading}
                      className={classes.bottomRedButton}
                      onClick={onImportLead}
                    >
                      {Translation('app.button.import')}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </StateContext.Provider>
      </DispatchContext.Provider>
      {/* remove participant dialog start */}
      <Dialog
        open={open}
        onClose={toggleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Translation('agencyCampaign.create.removeTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {Translation('agencyCampaign.create.removeText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnRemoveWrap}>
          <Button onClick={toggleOpen} variant="outlined" color="inherit">
            {Translation('app.button.cancel')}
          </Button>
          <Button onClick={handleOk} variant="contained" color="secondary" autoFocus>
            {Translation('app.button.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      {/* remove participant dialog end */}
      {/* remove participant success dialog start */}
      <Dialog
        open={removeSuccessDialogOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setRemoveSuccessDialogOpen(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.sentSuccess}>
          <div className={[classes.center, classes.circle].join(' ')}>
            <Icon className="fa fa-check" fontSize="large" color="primary" />
          </div>
          <span className={classes.sentSuccessText}>{Translation('agencyCampaign.create.removeSuccessText')}</span>
          <Button
            className={classes.flex1}
            onClick={() => setRemoveSuccessDialogOpen(false)}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {Translation('agencyCampaign.create.inviteBack')}
          </Button>
        </div>
      </Dialog>
      {/* remove participant success dialog end */}
      {/* invite success dialog start */}
      <Dialog
        open={inviteSuccessDialogOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            setInviteSuccessDialogOpen(false);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.sentSuccess}>
          <div className={[classes.center, classes.circle].join(' ')}>
            <Icon className="fa fa-check" fontSize="large" color="primary" />
          </div>
          <span className={classes.sentSuccessText}>{Translation('agencyCampaign.create.inviteSent')}</span>
          <Button
            className={classes.flex1}
            onClick={() => setInviteSuccessDialogOpen(false)}
            variant="contained"
            color="secondary"
            autoFocus
          >
            {Translation('agencyCampaign.create.inviteBack')}
          </Button>
        </div>
      </Dialog>
      {/* invite success dialog end */}

      {/* invite dialog start */}
      <InvitePreview
        open={inviteDialog}
        sentInvite={sentInvite}
        onOk={sendInvitationToParticipant}
        onClose={toggleInviteDialog}
        data={invitePreviewData}
      />
      {/* invite dialog end */}
      <Dialog open={!!showDiscradPrompt} onClose={() => setShowDiscardPrompt(false)}>
        <div className={classes.dialogContainer}>{Translation('agencyCampaign.create.confirmDiscard')}</div>
        <div className={classes.btnContainer}>
          <Button
            style={{ marginRight: 20 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              setShowDiscardPrompt(false);
              window.location.reload();
            }}
          >
            {Translation('global.text.yes')}
          </Button>
          {/* <Button
            variant="outlined"
            color="inherit"
            onClick={() => {
              setShowDiscardPrompt(false);
            }}
          >
            {Translation('global.text.cancel')}
          </Button> */}
        </div>
      </Dialog>
    </>
  );
};

export default CampaignDetailPage;
