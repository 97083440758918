import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { LayoutSplashScreen } from 'src/app/layout';
import { RootState } from '../../../../redux/store';
import { logout } from '../network/authSlice';
import { redirectMap, RedirectType } from '../../../common/types/common-types';

const Logout: FC<RouteComponentProps> = ({ history, location, match }) => {
  const dispatch = useDispatch();
  const hasAuthToken = useSelector<RootState, boolean>((state) => !!state.auth?.user);

  const redirectTo = useMemo(() => {
    const search = location.search;
    const params = new URLSearchParams(search);
    const redirect = params.get('redirect');
    return redirect && redirectMap[redirect as RedirectType] ? redirectMap[redirect as RedirectType] : '/';
  }, [location.search]);

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      dispatch(logout());
    }
    // eslint-disable-next-line
  }, []);

  return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to={redirectTo} />;
};

export default Logout;
