import { utils, writeFile } from 'xlsx';

export const createTemplate = () => {
  const workbook = utils.book_new();

  const exp = [
    ['firstName', 'lastName', 'email', 'phoneNumber', 'euResident', 'countryCode', 'remark' ],
    ['Wizard A', 'Test', 'test12@ss2.com', '09999999', 'No', '65','free text'],
    ['Wizard B', 'Test', 'test12@ss2.com', '12132112', 'Yes', '65','free text']
  ];
  const worksheet = utils.aoa_to_sheet(exp);
  utils.book_append_sheet(workbook, worksheet, 'AgentCode');
  writeFile(workbook, 'LeadUpload.csv', {bookType: 'csv'});
};
