import React, { FC, useState } from 'react';
import { Button, Dialog, IconButton, Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch } from 'react-redux';
import { CampaignItem, CampaignPaginateList, CampaignTypeItem } from '../../../types/campaign-types';
import PruTable from '../../../../../common/components/PruTable/PruTable';
import {
  WorkflowApprovalStatusEnum as StatusEnum,
  AGENCY_CAMPAIGN_BASE_PATH,
  AGENCY_CAMPAIGN_CAMPAIGN_PATH,
  AgencyCampaignTypeStatusEnum,
} from '../../../constants';
import { getDisplayDate } from '../../../../../common/utils/time-utils';
import { convertArrayToObject } from '../../../../../common/utils/common-utils';

type CampaignListProps = {
  isLoading: boolean;
  campaignList?: CampaignPaginateList;
  campaignTypeList?: CampaignTypeItem[];
  onRefresh: () => void;
  onChangePage: (page: number, rowsPerPage: number) => void;
  onSort: (sortingState: { key: string; value?: string }) => void;
};

const useStyles = makeStyles()((theme) => ({
  dialogContainer: {
    padding: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const CampaignList: FC<CampaignListProps> = ({
  isLoading,
  campaignList,
  campaignTypeList,
  onRefresh,
  onChangePage,
  onSort,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { classes } = useStyles();
  const Translation = (id: string) => intl.formatMessage({ id });

  const campaignTypeObject: any = campaignTypeList ? convertArrayToObject(campaignTypeList, '_id') : {};

  const renderStatus = (status: StatusEnum) => {
    let color = { backgroundColor: '#F0F0F0', color: '#666' }; // draft and other status
    switch (status.toLocaleLowerCase()) {
      case StatusEnum.PENDING:
        color = { backgroundColor: '#D6E0F1', color: '#38527E' };
        break;
      case StatusEnum.APPROVED:
        color = { backgroundColor: '#D0F6DF', color: '#008A37' };
        break;
      case StatusEnum.REJECTED:
        color = { backgroundColor: '#FFE4E6', color: '#E8192C' };
        break;
      default:
        break;
    }
    return <Chip label={status} style={color} />;
  };
  return (
    <>
      <PruTable
        hideListTitleRow={true}
        title={''}
        rowOnClicked={(rowData) => {
          history.push(`${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}/detail/${rowData.campaignId}`);
        }}
        disableBulkSelect
        operationDef={[]}
        columnDef={[
          {
            keyIndex: `name`,
            align: 'center',
            displayName: Translation(`agencyCampaign.common.campaignName`),
            renderData: (row) => String(row.name) || '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'name', value: sort['name'] });
            },
          },
          {
            keyIndex: 'campaignId',
            align: 'center',
            displayName: Translation('agencyCampaign.common.campaignId'),
            renderData: (row) =>
              row.status === AgencyCampaignTypeStatusEnum.ACTIVE ? String(row.campaignId) || '-' : '-',
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'campaignId', value: sort['campaignId'] });
            },
          },
          {
            keyIndex: 'startDate',
            align: 'center',
            displayName: Translation('agencyCampaign.common.startDate'),
            renderData: (row) => (row.startDate ? getDisplayDate(new Date(row.startDate), 'YYYY-MM-DD HH:mm') : '-'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'startDate', value: sort['startDate'] });
            },
          },
          {
            keyIndex: 'endDate',
            align: 'center',
            displayName: Translation('agencyCampaign.common.endDate'),
            renderData: (row) => (row.endDate ? getDisplayDate(new Date(row.endDate), 'YYYY-MM-DD HH:mm') : '-'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'endDate', value: sort['endDate'] });
            },
          },
          {
            keyIndex: `campaignType`,
            align: 'center',
            displayName: Translation(`agencyCampaign.common.campaignType`),
            renderData: (row) => row.agencyCampaign?.typeId?.name || '-',
          },
          {
            keyIndex: 'status',
            align: 'center',
            displayName: Translation('agencyCampaign.common.status'),
            renderData: (row) => {
              const status = Translation(`agencyCampaign.common.status.${row.status}`) || '';
              return renderStatus(status as StatusEnum);
            },
            // renderData: (row) => Translation(`agencyCampaign.common.status.${row.status}`) || "",
          },
          {
            keyIndex: 'rsvp',
            align: 'center',
            displayName: Translation('agencyCampaign.common.rsvp'),
            renderData: (row) => row.agencyCampaign?.rsvpStatus || '',
          },
          {
            keyIndex: 'updatedAt',
            align: 'center',
            displayName: Translation('agencyCampaign.common.lastUpdatedTime'),
            renderData: (row) => (row.updatedAt ? getDisplayDate(new Date(row.updatedAt), 'YYYY-MM-DD HH:mm') : '-'),
            sortable: true,
            onSort: (sort) => {
              onSort({ key: 'updatedAt', value: sort['updatedAt'] });
            },
          },
        ]}
        isLoading={isLoading}
        onRefresh={onRefresh}
        dataSource={campaignList?.docs}
        totalPages={campaignList?.totalPages}
        totalRecords={campaignList?.totalDocs}
        onChangePage={onChangePage}
        type="campaign"
      />
    </>
  );
};

export default CampaignList;
