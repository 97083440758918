import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignListParam, CampaignPaginateList, CampaignTypeItem } from '../../../types/campaign-types';
import { RootState } from '../../../../../../redux/store';
import { TokenInfo } from '../../../../Auth/types/auth-types';
import PruFilter, { PruFilterItemType } from '../../../../../common/components/PruTable/PruFilter';
import { ParamsProps } from '../../../../../common/components/ParamsProvider';
import { useDataProvider } from '../../../../../common/utils/hook-utils';
import { fetchCampaignAllTypeList, fetchCampaignList, fetchCampaignTypeList } from '../../../network/campaignCurd';
import CampaignList from './CampaignList';
import { Button, Dialog, DialogTitle, Icon, TextField, Input } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloseOutlined, SearchOutlined } from '@mui/icons-material';
import PruDialog from '../../../../../common/components/PruDialog';
import CampaignTypeSelectForm from './components/CampaignTypeSelectForm';
import { RouteComponentProps } from 'react-router';
import { AgencyCampaignTypeStatusEnum, AGENCY_CAMPAIGN_BASE_PATH, AGENCY_CAMPAIGN_CAMPAIGN_PATH } from '../../../constants';
import { toAbsoluteUrl } from 'src/app/common/utils';
import FilterSelectForm from './components/FilterSelectForm';
import { useIntl } from 'react-intl';
import { takeUIClickEvent } from '../../../../../common/ga/ga';
type CampaingsListingPageProps = {} & ParamsProps & RouteComponentProps;

const initialState: CampaignListParam = {
  limit: 20,
  page: 1,
  name: '',
};

const paramsInitiator = (initialParams?: Record<string, string>): CampaignListParam => {
  return initialParams
    ? {
        page: 1,
        limit: 20,
        name: initialParams.name ?? '',
      }
    : initialState;
};

const useStyles = makeStyles()(() => ({
  searchInput: {
    height: 46,
    padding: 0,
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
}));

const AgencyCampaignListPath = `${AGENCY_CAMPAIGN_BASE_PATH}${AGENCY_CAMPAIGN_CAMPAIGN_PATH}`;

const CampaignListPage: FC<CampaingsListingPageProps> = ({ initialParams, onChangeQueryParams, history }) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const user = useSelector<RootState, TokenInfo | undefined>((state) => state.auth.user);
  const [filterState, setFilterState] = useState<CampaignListParam>(paramsInitiator(initialParams));
  const [campaignListData, setCampaignListData] = useState<CampaignPaginateList>();
  const [campaignAllTypeList, setCampaignAllTypeList] = useState<CampaignTypeItem[]>();
  const [campaignTypeList, setCampaignTypeList] = useState<CampaignTypeItem[]>();
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [filterModalVisible, handleFilterModalVisiable] = useState<boolean>(false);
  const [filterCampaignType, setFilterCampaignType] = useState<string[]>([]);
  const [filterCampaignStatus, setFilterCampaignStatus] = useState<AgencyCampaignTypeStatusEnum[]>([]);
  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const { isLoading, refreshData } = useDataProvider<CampaignPaginateList>(
    async () => {
      try {
        if (onChangeQueryParams) {
          onChangeQueryParams(filterState);
        }
        return await fetchCampaignList(filterState, sortKey, dispatch);
      } catch (err) {}
    },
    setCampaignListData,
    false,
  );

  useDataProvider<CampaignTypeItem[]>(
      async () => {
          try {
              return (await fetchCampaignAllTypeList(dispatch)).sort((a, b) => {
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
              });
          } catch (err) {}
      },
      setCampaignAllTypeList,
      false,
  );

  useDataProvider<CampaignTypeItem[]>(
    async () => {
      try {
        return (await fetchCampaignTypeList(dispatch)).sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
      } catch (err) {}
    },
    setCampaignTypeList,
    false,
  );

  const onTypeSelected = (data: any) => {
    handleModalVisible(false);
    history.push(`${AgencyCampaignListPath}/create/${data}`);
  };

  const onFilterSelected = (data: {
    filterCampaignType: string[];
    filterCampaignStatus: AgencyCampaignTypeStatusEnum[];
  }) => {
    setFilterCampaignType(data.filterCampaignType);
    setFilterCampaignStatus(data.filterCampaignStatus);
    setFilterState({
      ...filterState,
      status: data.filterCampaignStatus.length > 0 ? data.filterCampaignStatus : undefined,
      campaignType: data.filterCampaignType.length > 0 ? data.filterCampaignType : undefined,
    });
    handleFilterModalVisiable(false);
  };

  const updateSortingKey = (sortingKey: { key: string; value?: string }) => {
    const sortingKeyArray = sortKey.filter((currentValue, index, arr) => {
      return currentValue.key !== sortingKey.key;
    });
    sortingKeyArray.unshift(sortingKey);
    setSortKey(sortingKeyArray);
  };

  const onSorter = () => {
    let updateList = campaignTypeList;
    if (updateList !== undefined && updateList?.length > 0) {
      if (direction === 'asc') {
        updateList = updateList.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
        });
        setCampaignTypeList(updateList);
        setDirection('desc');
      } else {
        updateList = updateList.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
        setCampaignTypeList(updateList);
        setDirection('asc');
      }
    }
  };

  useEffect(() => {
    refreshData();
  }, [filterState, sortKey]);

  return (
    <div style={{ backgroundColor: 'white', height: '100%' }}>
      <div style={{ display: 'flex', flex: 2, flexDirection: 'row', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', padding: 8 }}>
          <TextField
            variant="standard"
            style={{ background: "#F0F0F0", borderRadius: 50, minWidth: 200, paddingLeft: 10, height: 40, marginBottom: 0, marginTop: 0, fontFamily: 'Poppins, Helvetica, "sans-serif"' }}
            margin="dense"
            fullWidth
            placeholder="Search here"
            InputProps={{
              startAdornment: (
                <>
                  <SearchOutlined></SearchOutlined>
                </>
              ),
              classes: { input: classes.searchInput },
              disableUnderline: true,
            }}
            value={filterState.name}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                name: e.target.value,
              });
            }}
          />
          <>
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center', marginLeft: 15 }}
              onClick={() => {
                handleFilterModalVisiable(true);
              }}
            >
              <img
                style={{ height: '20px', width: '20px' }}
                alt="logo"
                src={toAbsoluteUrl('/media/icon/filter2.png')}
              />
            </div>
          </>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', padding: 8 }}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ width: 200 }}
            onClick={() => {
              takeUIClickEvent({
                journey: 'add_new_agency_campaign',
                stage: 'start',
                object_name: 'AddNewCampaign',
                object_type: 'BUTTON'
              })
              handleModalVisible(true);
            }}
          >
            {Translation('agencyCampaign.common.addNewCampaign')}
          </Button>
        </div>
      </div>
      <CampaignList
        isLoading={isLoading}
        campaignList={campaignListData}
        campaignTypeList={campaignTypeList}
        onRefresh={refreshData}
        onSort={(dataKey) => updateSortingKey(dataKey)}
        onChangePage={(page, rowsPerPage) => {
          setFilterState({
            ...filterState,
            page: page,
            limit: rowsPerPage,
          });
        }}
      />

      <Dialog
        open={createModalVisible}
        onClose={() => {
          handleModalVisible(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.common.newCampaign')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              handleModalVisible(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        {campaignTypeList ? (
          <CampaignTypeSelectForm
            onSave={onTypeSelected}
            onCancel={() => {
              handleModalVisible(false);
            }}
            confirmLoading={true}
            initialValues={campaignTypeList}
          />
        ) : null}
      </Dialog>
      <Dialog
        open={filterModalVisible}
        onClose={() => {
          handleFilterModalVisiable(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <DialogTitle>{Translation('agencyCampaign.dialog.filter')}</DialogTitle>
          <div
            style={{ padding: '16px 20px' }}
            onClick={() => {
              handleFilterModalVisiable(false);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
        {campaignAllTypeList ? (
          <FilterSelectForm
            onSave={onFilterSelected}
            onCancel={() => {
              handleFilterModalVisiable(false);
            }}
            confirmLoading={true}
            initialValues={{ filterCampaignType: filterCampaignType, filterCampaignStatus: filterCampaignStatus }}
            campaignTypeList={campaignAllTypeList}
            onSort={onSorter}
            isSort={true}
            direction={direction}
          />
        ) : null}
      </Dialog>
    </div>
  );
};

export default CampaignListPage;