import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from 'src/app/common/utils';

type completedProp = {
  title?:
    | {
        enUs: string;
        local: string;
      }
    | string;
  content?:
    | {
        enUs: string;
        local: string;
      }
    | string;
  isUpdate: boolean;
};

declare const window: Window & { ReactNativeWebView: any };

export const CompletedPage: FC<completedProp> = ({ title, content, isUpdate }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const returnToHome = () => {
    window.ReactNativeWebView.postMessage('{ "action" : "executeappfunction"}');
  };

  return (
    <div
      style={{
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        style={{ width: '72px', height: '72px', marginBottom: '24px' }}
        src={toAbsoluteUrl('/media/insurance-summary/reviewWebForm_success.png')}
        alt="insurance-summary-web-form-success"
      />
      <text
        style={{
          height: '26px',
          textAlign: 'center',
          marginBottom: '4px',
          color: '#333333',
          fontFamily: 'Arial',
          fontSize: 18,
          fontWeight: 600,
          lineHeight: '26px',
        }}
      >
        {title
          ? typeof title === 'string'
            ? title === ''
              ? Translation(`reviewWebForm.thankResponse.${isUpdate ? 'update' : 'complete'}.title`)
              : title
            : intl.locale === 'en'
            ? title.enUs
            : title.local
          : Translation(`reviewWebForm.thankResponse.${isUpdate ? 'update' : 'complete'}.title`)}
      </text>
      <text
        style={{
          height: '20px',
          textAlign: 'center',
          color: '#999999',
          fontFamily: 'Arial',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '20px',
        }}
      >
        {content
          ? typeof content === 'string'
            ? content === ''
              ? Translation(`reviewWebForm.thankResponse.${isUpdate ? 'update' : 'complete'}.content`)
              : content
            : intl.locale === 'en'
            ? content.enUs
            : content.local
          : Translation(`reviewWebForm.thankResponse.${isUpdate ? 'update' : 'complete'}.content`)}
      </text>
      <button
        style={{
          marginTop: '30px',
          padding: '8px 16px',
          backgroundColor: '#ffffff',
          border: '2px solid #f0f0f0',
          borderRadius: '8px',
          fontFamily: 'Arial',
          fontStyle: 'normal',
          fontSize: '14px',
          lineHeight: '20px',
          color: '#333333',
          fontWeight: 500,
        }}
        onClick={returnToHome}
      >
        {Translation('reviewWebForm.thankResponse.returnBtn')}
      </button>
    </div>
  );
};
