
import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  KPI_DASHBOARD_TITLE,
  FINANCIAL_AGENT_TITLE,
  AGENT_BASE_PATH,
  FINANCIAL_AGENT_AGENT_PATH,
  FINANCIAL_AGENT_NAMESPACE,
  DOWNLINE_AGENT_NAMESPACE,
  GL_AGENT_TITLE,
  AL_AGENT_TITLE,
  AL_AGENT_AGENT_PATH,
  GL_AGENT_AGENT_PATH,
  AL_AGENT_NAMESPACE,
  GL_AGENT_NAMESPACE,
  DOWNLINE_AGENT_PATH,
  AL_AGENT_PATH_FOR_GL,
  FC_AGENT_PATH_FOR_GL
} from './constants';
import AgentRoute from './pages/Agent/AgentRoutes';
import jwt_decode from 'jwt-decode';
import { TokenInfo } from '../Auth/types/auth-types';

export const KpiDashboardModuleConfig = (permissions: string[], intl: IntlShape, downlineCount: any): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
 const token = window.localStorage.getItem('jwt') || '';

 var allMenus = []
 if (token) {
  const tokenInfo = jwt_decode(token) as TokenInfo;
  const { role } = tokenInfo
  if(role === 'AGENT'){
    allMenus.push({
      title: Translation(FINANCIAL_AGENT_TITLE),
      path: `${AGENT_BASE_PATH}${FINANCIAL_AGENT_AGENT_PATH}`,
      namespace: FINANCIAL_AGENT_NAMESPACE,
      component: AgentRoute,
      enableRead: true,
      enableCreate: true,
      enableUpdate: true,
    })
  }
   else {
    if(downlineCount == '1'){
       allMenus = []
      allMenus.push(
        {
          title: Translation(AL_AGENT_TITLE),
          path: `${AGENT_BASE_PATH}${AL_AGENT_AGENT_PATH}`,
          namespace: AL_AGENT_NAMESPACE,
          component: AgentRoute,
          enableRead: true,
          enableCreate: true,
          enableUpdate: true,
        },
        {
          title: Translation(FINANCIAL_AGENT_TITLE),
          path: `${AGENT_BASE_PATH}${DOWNLINE_AGENT_PATH}`,
          namespace: DOWNLINE_AGENT_NAMESPACE,
          component: AgentRoute,
          enableRead: true,
          enableCreate: true,
          enableUpdate: true,
        },
      )
    }

    else {
       allMenus = []
      allMenus.push(
        {
          title: Translation(GL_AGENT_TITLE),
          path: `${AGENT_BASE_PATH}${GL_AGENT_AGENT_PATH}`,
          namespace: GL_AGENT_NAMESPACE,
          component: AgentRoute,
          enableRead: true,
          enableCreate: true,
          enableUpdate: true,
        },
        {
          title: Translation(AL_AGENT_TITLE),
          path: `${AGENT_BASE_PATH}${AL_AGENT_PATH_FOR_GL}`,
          namespace: GL_AGENT_NAMESPACE,
          component: AgentRoute,
          enableRead: true,
          enableCreate: true,
          enableUpdate: true,
        },
        {
          title: Translation(FINANCIAL_AGENT_TITLE),
          path: `${AGENT_BASE_PATH}${FC_AGENT_PATH_FOR_GL}`,
          namespace: DOWNLINE_AGENT_NAMESPACE,
          component: AgentRoute,
          enableRead: true,
          enableCreate: true,
          enableUpdate: true,
        },

      )
    }
  }
 }
  return {
    title: Translation(KPI_DASHBOARD_TITLE),
    path: AGENT_BASE_PATH,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    child: allMenus
  };
}
