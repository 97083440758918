/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { FC, useContext, useEffect } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { AuthPage, Logout } from './modules/Auth';
import { RootState } from '../redux/store';
import { Alert, AlertTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { AlertItem, dismissAlertItem } from '../redux/common/commonSlice';
import TokenHandler from './common/components/TokenHandler';
import { setTokenInfo } from './modules/Auth/network/authSlice';
import jwt_decode from 'jwt-decode';
import PruRoute from './common/components/PruRoute';
import { TokenInfo } from './modules/Auth/types/auth-types';
import { PRUFORCE_PATH, APPLICATION_BASE_PATH } from './modules/Application/constants';
import Callback from './modules/Auth/pages/Callback';
import ApplicationRoutes from './modules/Application/pages/ApplicationRoutes';
import ParamsProvider from './common/components/ParamsProvider';
import SingleSignOn from './common/components/SingleSignOn';
import ModuleProvider from './common/module/ModuleProvider';
import ErrorsPage from './modules/Error/ErrorsPage';
import { ToastContext } from './common/components/pru-toast/toast-context';
import { GlobalHelper } from './common/helpers/GlobalHelpers';
import { ModalServiceContext } from './common/components/pru-modal/modal-context';
import { ModalService } from './common/components/pru-modal/modal-service';
import Layout from './common/components/layout/layout';

type RoutesInternal = {
  user?: TokenInfo;
  alertState: AlertItem[];
  isExpired: boolean;
};

const useStyles = makeStyles()(() => ({
  alertContainer: {
    position: 'fixed',
    right: 0,
    top: '10vh',
    zIndex: 100,
  },
}));

export const Routes: FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const toastRef = useContext(ToastContext);
  GlobalHelper.setGlobalToastRef(toastRef);

  const modalRef = useContext(ModalServiceContext);
  GlobalHelper.setGlobalModalRef(modalRef);

  GlobalHelper.setGlobalToastRef(toastRef);

  const { user, alertState, isExpired } = useSelector<RootState, RoutesInternal>((state) => {
    const token = window.localStorage.getItem('jwt') || '';

    let tokenInfo: TokenInfo | undefined = undefined;
    if (token) {
      tokenInfo = jwt_decode(token) as TokenInfo;
    } else {
      const redirectLink = window.localStorage.getItem('redirect');
      if (!redirectLink && location.pathname.startsWith('/agencyCampaign/campaign/detail')) {
        window.localStorage.setItem('redirect', location.pathname);
      }
    }

    return {
      user: tokenInfo,
      alertState: state.common.alertState,
      isExpired: !!state.auth.isExpired,
    };
  }, shallowEqual);

  useEffect(() => {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      dispatch(setTokenInfo(jwt_decode(token) as TokenInfo));
    }
    ModalService.init();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.alertContainer}>
        {alertState &&
          alertState.map((alertItem, index) => {
            setTimeout(() => dispatch(dismissAlertItem({ index })), 5000);
            return (
              <Alert
                key={`alert-item-${index}`}
                severity={alertItem.severity}
                onClose={() => dispatch(dismissAlertItem({ index }))}
              >
                {alertItem.title && <AlertTitle>{alertItem.title}</AlertTitle>}
                {alertItem.content}
              </Alert>
            );
          })}
      </div>
      <Switch>
        <PruRoute
          path="/callback"
          render={(props) => (
            <ParamsProvider {...props} acceptKeys={['authorizationCode']}>
              <Callback />
            </ParamsProvider>
          )}
        />
        <PruRoute
          path="/:redirect/callback"
          render={(props) => (
            <ParamsProvider {...props} acceptKeys={['authorizationCode']}>
              <Callback />
            </ParamsProvider>
          )}
        />
        <PruRoute
          path={APPLICATION_BASE_PATH}
          render={(props) => (
            <SingleSignOn>
              <ApplicationRoutes {...props} />
            </SingleSignOn>
          )}
        />
        <PruRoute path="/error" component={ErrorsPage} />
        <PruRoute path="/logout" component={Logout} />
        <Redirect from={PRUFORCE_PATH} to={APPLICATION_BASE_PATH} />
        {user && <Redirect from="/auth/login" to="/" />}
        <SingleSignOn>
          {!user ? (
            <AuthPage />
          ) : (
            <ModuleProvider>
              <TokenHandler />
              {!isExpired && <Layout />}
            </ModuleProvider>
          )}
        </SingleSignOn>
      </Switch>
    </>
  );
};
